@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/utilities/flex';

@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/utilities/background';
@import '~bootstrap/scss/utilities/text';
@import '~bootstrap/scss/utilities/align';
@import '~bootstrap/scss/utilities/borders';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/modal';

@each $color,$value in $theme-colors {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      @include bg-variant(".bg#{$infix}-#{$color}", $value);
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-left   { text-align: left !important; }
    .text#{$infix}-right  { text-align: right !important; }
    .text#{$infix}-center { text-align: center !important; }

    .w#{$infix}-0 {width: 0px !important;}
    .w#{$infix}-25 {width: 25% !important;}
    .w#{$infix}-50 {width: 50% !important;}
    .w#{$infix}-100 {width: 100% !important;}
    .w#{$infix}-auto {width: auto !important;}

    .bg#{$infix}-none {background-color: transparent !important;}

    // borders
    .border#{$infix}         { border: $border-width solid $border-color !important; }
    .border#{$infix}-top     { border-top: $border-width solid $border-color !important; }
    .border#{$infix}-right   { border-right: $border-width solid $border-color !important; }
    .border#{$infix}-bottom  { border-bottom: $border-width solid $border-color !important; }
    .border#{$infix}-left    { border-left: $border-width solid $border-color !important; }

    .border#{$infix}-0        { border-width: 0 !important; }
    .border#{$infix}-top-0    { border-top: 0 !important; }
    .border#{$infix}-right-0  { border-right: 0 !important; }
    .border#{$infix}-bottom-0 { border-bottom: 0 !important; }
    .border#{$infix}-left-0   { border-left: 0 !important; }

    .border#{$infix}-2 {border-width: 2px !important;}

    .border#{$infix}-fch:first-child         { border: $border-width solid $border-color !important; }
    .border#{$infix}-fch-top:first-child     { border-top: $border-width solid $border-color !important; }
    .border#{$infix}-fch-right:first-child   { border-right: $border-width solid $border-color !important; }
    .border#{$infix}-fch-bottom:first-child  { border-bottom: $border-width solid $border-color !important; }
    .border#{$infix}-fch-left:first-child    { border-left: $border-width solid $border-color !important; }

    .border#{$infix}-fch-0:first-child        { border: 0 !important; }
    .border#{$infix}-fch-top-0:first-child    { border-top: 0 !important; }
    .border#{$infix}-fch-right-0:first-child  { border-right: 0 !important; }
    .border#{$infix}-fch-bottom-0:first-child { border-bottom: 0 !important; }
    .border#{$infix}-fch-left-0:first-child   { border-left: 0 !important; }

    @each $color,$value in $theme-colors {
      .border#{$infix}-#{$color} {border-color: $value !important;}
    }
  }
}

i {
  line-height: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}