.screenint{
  display:block;
  height: 82%;
  width: 90vw;
  margin-left: 5vw;
  margin-bottom:9%;
  z-index:1;

  &.first{
    background:url(/static/img/international/int_1.jpeg);
    background-size: cover;
    height:19vw;
    margin-top:5px;
  }
  &.second{
    margin-top:0;
    background-image:url(/static/img/international/int_2.jpeg);
    background-size:cover;
    position: absolute;
    width: 43%;
    top: -2vw;
    right: 5vw;
    background-position: 0;
    height: 74%;
    bottom: 20%;
    background-clip: border-box;
  }
  &.fourth{
    background:url(/static/img/international/int_4.jpeg);
    background-size: cover;
    height:19vw;
    margin-top:5px;
  }
}

.textbox.int{
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: 3vw;
  text-align: left;
  justify-content: center;
  color: #000;
  width: 52vw;
  left: calc(50% - 25vw);
  line-height: 1.1;
}

@media screen and (max-width: 1024px) {
  .screenint{
    
    &.first{
      position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
      margin: 40px 0 0 0;
      height: 21vw;
    }
    &.second{
      position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
      margin: 40px 0 0 0;
      height: 90vw;
    }
    &.fourth{
      position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
      margin: 25px 0 0 0;
      height: 21vw;
    }
  }
}