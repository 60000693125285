@include media-breakpoint-down(md) {
  .parse-content-simple {
    li {
      margin-bottom: 10px;
      position: relative;
      padding-left: 23px;
      &:before {
        content: "";
        width: 6px;
        height: 6px;
        display: block;
        background: $red;
        border-radius: 500px;
        position: absolute;
        top: 6px;
        left: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}