.promo.news{
  
  .text-board{
  top: 100%;
  color: #393939;
  padding: 1vw 0 0 0;
  font-weight: 500;

  &__date{
    margin-bottom: 0.26vw;
    margin-top: 0.5vw;
    font-size: 15px;
  }

  &__anons{
    font-size: 16px;
    color: #6f6f6f;
    font-weight: 500;
    margin-bottom: 0.9vw;
    line-height: 18px;
  }
  &__link{
    font-weight: 500;
    text-decoration: none;
    font-size: 16px;
    color: $red;
    .arrow{
      margin-right: 14px;
    }
  }
  &__title{
    margin-top: 0.6vw;
    line-height: 26px;
  }
  }
}
.news-title{
  padding-top:2vw;
  width: 102.3%;
  background:#ffffff;
}
.stocks__container.news{
  .stocks__text{
    font-size:15px;
    color: #6f6f6f;
  }
}

@media screen and (max-width: 1024px) {
  .promo.news{
  
    .text-board{
    top: 100%;
    color: #393939;
    padding: 1vw 0 0 0;
    font-weight: 500;
  
    &__date{
      margin-bottom: 0.26vw;
      margin-top: 0.5vw;
      font-size: 15px;
    }
  
    &__anons{
      font-size: 14px;
      color: #6f6f6f;
      font-weight: 500;
      margin-bottom: 0.9vw;
      line-height: 18px;
    }
    &__link{
      font-weight: 500;
      text-decoration: none;
      font-size: 16px;
      color: $red;
      .arrow{
        margin-right: 14px;
      }
    }
    &__title{
      margin-top: 0.6vw;
      line-height: 26px;
    }
    }
  }
  .news-title{
    padding-top:2vw;
    width: 100%;
    background:#ffffff;
  }
  .stocks__container.news{
    .stocks__text{
      font-size:15px;
      color: #6f6f6f;
    }
  }
}