.screenavia{
  display:block;
  height: 82%;
  width: 90vw;
  margin-left: 5vw;
  margin-bottom:9%;
  z-index:1;
  
  &.first{
    background:url(/static/img/avia/avia_1.jpg);
    background-size: cover;
    height:19vw;
    margin-top:5px;
  }

  &.second{
    margin-top:0;
    background-image:url(/static/img/avia/avia_2.jpeg);
    background-size:cover;
    position: absolute;
    width: 43%;
    top: -2vw;
    right: 5vw;
    background-position: 0;
    height: 74%;
    bottom: 20%;
    background-clip: border-box;
  
  }

  &.third{
    background:url(/static/img/avia/avia_3.jpeg);
    background-size: cover;
    height:19vw;
    margin-top:5px;
  
  }

}
ul{
  &.relations{
    &.avia{
      &-first{
        margin: 60px auto;
        width: 70%;
      }
      &-second{
        .relations__item{
          margin-bottom: 1.5vw;
        }
      }
    }
  }
}

.textbox{
  &__border{
    &.avia{
      text-align: center;
      margin: .6vw auto 2vw auto;
      width: 75%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .avia-textbox{
    margin-left: 60px !important;
    text-align: center !important;
  }
  .screenavia{

    &.first{
      position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
      margin: 40px 0 0 0;
      height: 21vw;
    }
    &.second{
      position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
      margin: 0 0 0 0;
      height: 90vw;
    }
    &.third{
      position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
      margin: 40px 0 0 0;
      height: 44vw;
    }
  }

  ul.relations.avia-first{
    margin: 20px auto !important;
  }
}