$heignt-content-map: calc(100vh - 240px);
.map {
  &__title {
    position: relative;
    .btn-back {
      position: absolute;
      right: 0;
      font-size: 16px;
      text-decoration: underline;
      top: 0px;
      color: #ca1226;
    }
  }

  .d-none-error {
    opacity: 0;
  }
  &-sidebar {
    border-right: 1px solid #e4e4e4;
    position: relative;
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 102px;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  &-search {
    &__btn {
      max-width: 144px !important;
      text-align: right;
      margin-top: 6px;
      button {
        min-height: 35px;
        padding: 9px 35px 8px 35px;
      }
    }
  }

  &-area {
    height: $heignt-content-map;
    width: 100%;
  }

  &-card {
    border-bottom: solid 1px #e4e4e4;
    padding: 20px 0;
    margin-right: 20px;
    &:last-child {
      margin-bottom: 100px;
      border: none;
    }
    &:first-child {
      border-top: solid 1px #e4e4e4;
    }
    &-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 13px;
      > div {
        &:last-child {
          flex-basis: 140px;
          text-align: right;
        }
      }
      &__link {
        color: #6f6f6f;
        text-decoration: none;
        font-size: 12px;
        font-weight: 500;
        position: relative;
        top: -4px;
        &:hover {
          opacity: 0.7;
        }
        i {
          position: relative;
          top: 4px;
          font-size: 16px;
        }
        &--empty {
          &:hover {
            opacity: 1;
            cursor: text;
          }
        }
      }
    }

    &-container {
      overflow-y: auto;
      height: calc(#{$heignt-content-map} - 40px);
    }

    &-body {
      &__nextfs {
        div {
          margin-bottom: 20px;
        }
      }
      &__keyval {
        display: flex;
        div {
          &:first-child {
            flex-basis: 45px;
          }
        }
      }
      &__list {
        > li {
          margin-bottom: 16px;
          font-size: 12px;
          color: #6f6f6f;
          position: relative;
          padding-left: 35px;
          line-height: 16px;
          &:last-child {
            margin-bottom: 0;
          }
          > i {
            position: absolute;
            left: 0;
            top: -2px;
            font-size: 20px;
            &.icon-placeholder-copy {
              font-size: 18px;
            }
            &.icon-call {
              font-size: 16px;
            }
            &.icon-thermometer {
              font-size: 24px;
            }
            &.icon-shield {
              font-size: 24px;
                left: -1px;
            }
            &.icon-blockchain {
              font-size: 23px;
            }
          }

          &:nth-child(4) {
            margin-top: 22px;
          }

          &:nth-child(6) {
            margin-top: 25px;
          }
        }
        &--blue {
          color: #8d96ab !important;
          > i {
            color: #4c84ff;
          }
        }
        &--active {
          .map-card-header__link {
            color: $red
          }
        }
        &--through {
          text-decoration: line-through;
          i {
            color: #abafaf;
          }
        }
        &--gray {
          color: #8d96ab !important;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .map {
    position: relative;

    .labeled__label {
      display: none;
    }

    &-card {
      &-header-mobile {
        span,
        strong {
          font-size: 12px;
        }
        span {
          display: block;
          color: #6f6f6f;
        }
      }
    }

    &.page-1 {
      // .map-search {
      //   &__select {
      //     position: absolute !important;
      //     z-index: 2;
      //     padding: 40px 20px !important;
      //     bottom: 0;
      //     left: 0;
      //     &:before {
      //       content: "";
      //       display: block;
      //       width: 100%;
      //       height: calc(100% + 82px);
      //       opacity: 0.36;
      //       background-image: linear-gradient(to bottom, rgba(70, 70, 70, 0), rgba(86, 86, 86, 0.66));
      //       position: absolute;
      //       top: -83px;
      //       left: 0;
      //     }
      //     .labeled {
      //       position: relative;
      //       z-index: 1;
      //     }
      //     .cont {
      //       background: #fff;
      //     }
      //   }
      // }

      // .d-inline-block {
      //   &.mb-3 {
      //     margin-bottom: 0 !important;
      //   }
      // }
    }
  }

  &.page-2 {
    .map-sidebar {
      // order: 2;
    }

    .map-area {
      height: 50vh;
      width: 100%;
    }

    .scrollbar {
      height: auto;
      overflow: visible;
    }
  }

  .map-mobile {
    .map-sidebar {
      border: none;
      // margin-top: 20px;
      margin-bottom: 20px;
      &:after {
        display: none;
      }
    }
    .map-card {
      border: none;
      border-radius: 4px;
      box-shadow: 0 0 9px 1px rgba(121, 132, 172, 0.18);
      margin-right: 0;
      padding: 10px 20px;
      margin-bottom: 10px;
    }

    .map-card-header-mobile {
      margin-bottom: 0;
    }

    .map-card-body,
    .map-card-header:not(.map-card-header-mobile) {
      display: none;
      margin-bottom: 0;
    }

    .map-card-body__list--active {
      .map-card-body,
      .map-card-header:not(.map-card-header-mobile) {
        display: flex;
      }
      .map-card-body {
        margin-top: 10px;
      }
    }
    .map-card-body__isFilial {
      display: block;
      margin-top: 10px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .map {
    &.container-full {
      max-width: 100%;
    }
  }
}