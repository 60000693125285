$primary: #ca1226;
$text-muted: #ababab;
$pink: #ff5b5c;

@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

$theme-colors: map-merge(
  (
    "gray-2": #909498,
    "purple": #8496c0,
    "gray-100": rgba(244, 244, 244, 0.54),
    "gray-200": #f0f0f0,
    "gray-300": #909498,
    "gray-400": #6f6f6f,
    "gray-800": #393939,
    "muted": #ababab,
    "pink": $pink,
    "transparent": transparent
    // "gray-100": $gray-100,
    // "gray-200": $gray-200,
    // "gray-300": $gray-300,
    // "gray-400": $gray-400,
    // "gray-500": $gray-500,
    // "gray-600": $gray-600,
    // "gray-700": $gray-700,
    // "gray-800": $gray-800,
    // "gray-900": $gray-900
  ),
  $theme-colors
);