.feedbacks {
  &__desk {
    font-size: 12px;
    line-height: 16px;
  }

  &-social {
    &__list {
      padding-left: 86px;
      margin-top: 25px;
    }
    &__item {
      display: inline-block;
      margin-right: 20px;
      font-size: 25px;

      &:last-child {
        margin-right: 0;
      }

      a {
        color: #bbc0c4;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  &-contact {
    line-height: 16px;
    padding-left: 35px;
    margin-top: 6px;
    margin-left: 50px;
    // li {
    //   margin-bottom: 35px;
    //   position: relative;
    //   &:last-child {
    //     margin-bottom: 0;
    //   }
    // }

    &__title {
      font-weight: 500;
      margin-bottom: 6px;
    }

    &__desk {
      font-size: 12px;
    }

    [class*='icon-'] {
      position: absolute;
      left: -35px;
      top: 0;
      font-size: 19px;
      color: $red;
    }

    .icon-mail-point {
      font-size: 26px;
      left: -38px;
    }
  }

  .icon-mail-send {
    color: #aeb4bf;
    font-size: 85px;
  }
}

@include media-breakpoint-down(md) {
  // .mobile-revers {
  //   display: flex;
  //   flex-flow: column;
  //   &-order {
  //     &-1 {
  //       order: 1;
  //     }
  //     &-2 {
  //       order: 2;
  //     }
  //     &-3 {
  //       order: 3;
  //     }
  //   }
  // }
  .feedbacks {
    &-social {
      text-align: center;
      &__list {
        padding: 0;
      }
    }
    &-contact {
      // display: none;
      margin: 0;
    }
  }
}