.header{
  width:100%;
  height: 7vw;
  position: sticky;
  background-color:#fff;
  display:flex;
  justify-content:space-between;
  align-items:center;
  z-index:10;

  &.sticky{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%
  }
  &.news{
    top: 48px;
    &:before{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      top: -48px;
      height: 48px;
      width: 100%;
      background-color: #fff;
    }
}

  &__left{
    display:flex;
    height:2.5vw;
    margin-left:5vw;
    
    &__logo{
      height: 35px;
      margin-top: 0;
      margin-right: 0.8vw;
    }
    
    &__phone{
      display: flex;
      flex-direction: column;
      height: 2.6vw;
      align-items: flex-end;
      padding-left: .4vw;
      border-left: 0.01vw solid #d7d7d7;
      white-space: nowrap;

      &__oldsite{
        color: #6f6f6f;
        font-size: 12px;
        margin-top: 10px;
      }

      &__number{
        height:0.8vw;
        font-family:OpenSans-Bold;
        font-size:16px;
        line-height:0.9vw;
        letter-spacing:normal;
        color:#6f6f6f;
        margin-left:0.5vw;
      }

      &__icon{
        height:1vw;
        margin-right: 10px;
        font-size: 16px;
      }
    }
  }
  &__right{
    display:flex;
    height:2.5vw;
    margin-right:4vw;
    
    &__nav{
      display:flex;
      flex-wrap:wrap;
      height: 2.5vw;
      padding-right: 1vw;
      border-right: 0.01vw solid #d7d7d7;
    
      li{
        display:flex;
        margin-right:1vw;
        align-items:center;

        &:hover{

        .header__right__nav__icon{
          color: #ca1226;
        }

        a{
          color: #ca1226 !important;
        }
        }
      
        &:last-of-type{
          margin:0;
        }
        
        a{
          text-decoration:none;
          height:0.8vw;
          font-family:OpenSans-Bold;
          font-size:14px;
          line-height:1.14;
          color:#6f6f6f;
          align-items: center;
          display: flex;

          &:hover{
            color: #ca1226;
            text-decoration: underline;
          }
        }
      }

      &__icon{
        font-size:24px;
        margin-right: 10px;
      }
    
      &__menu{
        background-color:transparent;
        padding:0;
        font-size: 24px;
        border:none;
        display: flex;
        cursor: pointer;
        align-items: center;
        color: #6f6f6f;

        &.icon-close{
          font-size: 20px;
        }
      
        &:focus{
          outline:none;
        }

        &:hover{
          color: #ca1226;
        }
      }
    }
    
  .login-panel{
    margin-left:0.8vw;
    display:flex;
    align-items:center;
    
    button{
      font-family:OpenSans-Bold;
      font-size:0.8vw;
      line-height: 0.1vw;
      border:none;
      cursor:pointer;
    }
    
    &__login{
      background-color:transparent;
      padding:0;
      text-decoration:none;
      font-weight: 500;
      height: 14px;
      line-height: 1;
      font-size: 16px;
      margin-right:1.6vw;
    }
    
    &__registration{
      font-weight: 500;
      height:40px;
      border-radius:1.3vw;
      background-color:#ca1226;
      padding:13px 19px;
      color: #fff;
      text-decoration: none;
      line-height: 1;
      font-size: 16px;
      transition: color .3s,background-color .3s;
      
      &:hover{
        background-color: rgba(202,18,38,.75);
        color: #fff !important;
      }
    }
  }
  }
}
@media screen and (max-width: 1024px) {
  .header{
    height: 60px;
    width: 100vw;
    top: 0;
    align-items: center;
    position: fixed;

    .login-panel{
      display: none;
    }

    &__right{
      height: 30px;
      align-items: center;

      &__nav{
        border: none;
        padding: 0;
        height: auto;
        margin: 0;

        li{
          display: none;
        
          &:last-of-type{
            display: block;
          }
        }

        &__menu{
          width: 30px;
          height: 18px;
          
          &:hover{
            color: #6f6f6f;
          }
        }
      }
    }

    &__left{
      height: auto;

      &__phone{
        white-space: nowrap;
        position: absolute;
        display: block;
        top: 50px;
        border: none;
        font-size: 14px;
        line-height: 1;
        width: 100%;
        background: #fff;
        left: 0;
        padding-bottom: 20px;
        height: fit-content;

        &__number{
          font-size: 1em;
          width: 170px;
        }

        &__icon{
          height: auto;
          margin-right: 10px;
          margin-left: 25px;
        }
      }

      &__logo{
        height: 30px;
        margin: 0;
      }
    }

    &-foot{
      position: fixed !important;
      box-shadow: 0 -1px 4px 1px rgba(0, 0, 0, 0.08);
      background-color: #ffffff;
      flex-wrap: wrap;
      width: 100%;
      padding-top: 0;
      height: 50px;
      bottom:0;
      z-index: 2;

      &.hide {
        @media (max-width: 1024px) {
          display: none !important;
        }
      }

      .dropdown-menu {
        z-index: 1000;
      }

      .f-menu {
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        border: none;
        transform: translate(0, -100%) !important;

        border-radius: 4px 4px 0 0;
        box-shadow: 0 -5px 10px 3px rgba(137, 145, 174, 0.2);
      }

      &-list{
        height: 50px;
        display: flex;
        justify-content: space-around;
        align-items: center
      }

      &-item{
        position: static !important;
        display: inline-flex;
        text-align: center;
        width: 60px;
        height: 35px;
        justify-content: center;
        align-items: center;
        
        .contacts-icon-mobile{
          font-size: 24px !important;
          margin-top: -4px;
          margin-bottom: -1px;
        }

        a{
          font-size: 10px;
          text-decoration: none;
          color: #6f6f6f;
          &:visited, &:hover {
            text-decoration: none;
            color: #6f6f6f;
          }
        }
      }

      &-icon{
        display: block;
        font-size: 20px;
      }
    }
  }
}

.colored-link{
  color: #ca1226 !important;
}

.underlined-link{
  border-bottom: 2px #6f6f6f solid !important;
}

@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .semi-desktop{
    display: none;
  }

  .header{
    width: 100%;
    height: 7vw;
    margin-left: 0;
    position: sticky;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    // transform: scale(1.15);

    &.sticky{
      width: 100%;
    }

    &__right{

      &__nav{

        li{

          a{

            font-size: 10px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1366px) {
  .header__left__phone__number{
    font-size: 14px;
  }
}