@import 'variables';
@import 'bootstrap/scss/bootstrap-grid';
@import 'bootstrap';
@import 'labeled';
@import 'helpers';
@import 'input';
@import 'checkbox';
@import 'card';
@import 'typo';
@import 'button';
@import 'radio';
@import 'tip';
@import 'layout';
@import 'mobile';
@import 'icons';
@import 'parse-content-simple';
@import 'nav';

@import 'page-order-success';

body {
  font-size: 14px !important;
}