textarea {
  width: 100%;
  border: 1px solid #d7d7d7;
  min-height: 72px;
  padding: 6px 12px;
  border-radius: 3px;

  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #393939;

  outline: none;
}