.c-button {
  min-height: 40px;
  border-radius: 3px;
  background-color: #ca1226;
  color: #fff;

  padding: 10px 35px;
  word-break: break-word;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  border: 1px solid #ca1226;

  transition: color .3s, background-color .3s;
  cursor: pointer;

  &:disabled {
    border-color: #d8d8d8;
    background-color: #d8d8d8;
    color: #909498;
  }

  &:not(:disabled):hover {
    color: #fff;
    background-color: rgba(#ca1226, .75);
  }

  &.white {
    background-color: #ffffff;
    color: #ca1226;
    &:not(:disabled):hover {
      color: #ca1226;
      background-color: rgba(#ca1226, .1);
    }
  }

  &.pill {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    min-height: 30px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}