.screentherm{
  background-size: cover;

  &.first{
    width: 90vw;
    height: 21.5vw;
    margin-left: 5vw;
    background-image: url(/static/img/therm/therm-1.jpg)
  }


}
.bottom-text{
  &.therm{
    &.widther{
      width: 90vw;
      margin-left: 5vw;
    }
  }
}
.textbox{

  &.therm{
    justify-content: left;
    left: 0;
    width: fit-content;
    margin: 0 auto;

    .second{
      width: 760px;
    }

    &.third{
      margin-left: 5%;
    }

    &.fourth{
      margin-left: 5%;
    }
  }
}

.therm-list{
  background-color: #ca1226;
  color: #ffffff;
  border-radius: 100px;
  padding: 1.5vw;
  font-size: 6vw;
}

@media screen and (max-width: 1024px) {
  .rel-box{
    display: flex;
    align-items: flex-start;
    margin-top: 30px;
  }
  ul.relations.cargo-types{
    display: inline-block !important;
    width: 65%;
    margin: 0;

    &:first-of-type{
      width: 30%;
      margin-left: 5%;
    }

    .relations__item{
      height: 78px;
      display: flex;
      align-items: center;
    }
  }
  .screentherm{
    background-size: cover;
  
    &.first{
      width: 100vw;
      height: 45vw;
      margin-left: 0;
      background-position-x: -75vw;
      background-image: url(/static/img/therm/therm-1.jpg);
      margin-top: 20px;
      margin-bottom: 45px;
    }
  }
  .ftltherm.first{
    margin-top: 20px;
      margin-bottom: 45px;
      width: 100vw;
      height: 45vw;
      margin-left: 0;
      background-position-x: -75vw;
  }
  
  .therm-list{
    background-color: #ca1226;
    color: #ffffff;
    border-radius: 100px;
    padding: 15px 15px;
    font-size: 3rem;
  }

  .textbox{

    &.therm{
      
      &.second{
        margin-top: 30px; 
      }

      &.third{
        margin-top: 50px;
        margin-left: 3vw;
      }

      &.fourth{
        margin-top: 30px;
        margin-left: 3vw;
      }
    }

    &__border{

      &.therm{
        width: 95% !important;
      }
    }
  }
}