@import "reset";
@import "variables";
@import "fonts";
@import "grid";
@import "form";
@import "buttons";
@import "card";
@import "helper";
@import "alert";
@import "header";
@import "nav-menu";
@import "main-page";
@import "footer";
@import "fullpage";
@import "3pl";
@import "del";
@import "bld";
@import "thermal";
@import "express";
@import "promo";
@import "ui/index";
@import "news";
@import "tracking";
@import "feedbacks";
@import "vacancy";
@import "documents";
@import "map";
@import "vendor/steps";
@import "vendor/step";
@import "vendor/tabs";
@import "vendor/tab-pane";
@import "vendor/ui-element";
@import "about";
@import "errors";
@import "response";
@import "ie";
@import "avia";
@import "customs";
@import "international";

body {
  font-family: Roboto;
  min-height: 100vh;
  background: #fff;
  color: #393939;
  font-size: 0.7vw;
  padding-top: calc(7vw + 48px);
  max-width: 1600px;
  margin: 0 auto !important;
}
.fp-enabled {
  body {
    padding-top: calc(7vw + 48px) !important;
    margin: 0 !important;
    max-width: none;
  }
}
.container-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-center-box {
  width: 15vw;
}
.display-1 {
  margin: 0;
  font-size: 1vw;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.75;
  letter-spacing: normal;
  color: #393939;
  display: block;
}
.login {
  height: 100vh;
  position: relative;
}
.login-title {
  position: absolute;
  top: -1.45vw;
  left: 0;
  text-align: center;
  width: 100%;
}
.login .card {
  padding-top: 1.4vw;
}

a:hover {
  color: #ca1226 !important;
  text-decoration: none !important;
}

@media screen and (max-width: 1024px) {
  .fp-enabled body {
    padding-top: 148px !important;
    overflow: auto;
  }
  html.fp-enabled {
    overflow: auto;
  }
  body {
    padding-top: 48px;
  }
  .page {
    margin-top: 20px;
  }
}
