.el-upload {
  &__input {
    display: none;
  }
  &--text {
    &:focus {
      outline: 0;
    }
  }
  &__btn {
    font-weight: 500;
    font-size: 12px;
    color: $red;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
    i {
      font-size: 15px;
      position: relative;
      top: 3px;
    }
  }
  &-list {
    &__item {
      margin-bottom: 15px;
      &:first-child {
        margin-top: 20px;
      }
    }
    .el-icon-close-tip {
      display: none;
    }
    .el-icon-close {
      width: 15px;
      height: 15px;
      display: inline-block;
      overflow: hidden;
      color: #909498;
      margin-left: 10px;
      position: relative;
      top: 4px;
      &:hover {
        color: $red;
        cursor: pointer;
      }
    }
    .el-icon-document {
      font-size: 25px;
      color: #dcdcdc;
      font-size: 25px;
      color: #dcdcdc;
      position: relative;
      top: 8px;
      margin-right: 5px;
    }
    &__item {
      &:focus {
        outline: 0;
      }
      &-name {
        font-weight: 500;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .el-upload {
    &__btn {
      margin-bottom: 20px;
      display: inline-block;
    }
    &-list {
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
        &__item {
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
          position: relative;
          width: 100%;
          padding-right: 15px;
          padding-left: 15px;
          margin-bottom: 0;
          height: 64px;
          &:first-child {
            margin-top: 0;
          }

          &-name {
            background: #f4f4f4;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #6f6f6f !important;
            font-size: 10px;
            font-weight: normal;
            padding: 0 10px 6px 10px;
            border-radius: 4px;
            text-align: center;
          }
        }
        .el-icon-document {
          color: #737373;
          margin: 0 0 15px 0;
          display: inline-block;
          font-size: 28px;
          display: block;
        }

        .el-icon-close {
          position: absolute;
          right: 15px;
          font-size: 8px;
        }
    }
  }
}