.about{
  &-list{
    width: 80vw;
    margin-top: 3vw;
    margin-left: 10vw;
    display: flex;
    justify-content: space-between;
  }

  &-item{
    display: inline;
    font-size: 1vw;

    &-double{
      padding: 0;
      display: inline-flex;
      width: 20vw;
      height: auto;
    }
  }

  &-bold{
    display: block;
    font-weight: 600;
    font-size: 1.5vw;
  }

}

.years{
  margin-left:6vw;
  margin-top: 3vw;

  &-item{
    font-size: 1vw;
    height: 4vw;
  }

  &-round{
    color: #fff;
    background-color: $red;
    border-radius: 100px;
    padding: 1vw 0.5vw;
    margin-right: 1vw;
  }

  &:before{
    content: "";
    position: relative;
    float: left;
    display: block;
    width: .15vw;
    height: 21vw;
    left: 1.7vw;
    z-index: -1;
    background-color: $red;
  }
}

@media screen and (max-width: 1024px) {
  .about{
    &-list{
      width: 90vw;
      margin-top: 3vw;
      margin-left: 5vw;
      display: block;
    }
  
    &-item{
      display: flex;
      font-size: 14px;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;

      &.special{
        margin-top: 0px;
        margin-bottom: 10px;
        align-items: flex-start;
        line-height: 1; 

        .about-bold{
          margin-top: -3px;
        }
      }
  
      &-double{
        padding: 0;
        display: inline-flex;
        width: 20vw;
        height: 1.6vw;
      }
    }
  
    &-bold{
      display: inline-block;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      height: 35px;
    }
  
  }
  
  .years{
    margin-left:6vw;
    margin-top: 3vw;
    margin-right: 10px;
  
    &-item{
      font-size: 14px;
      height: 60px;
      padding-left: 60px;
    }
  
    &-round{
      color: #fff;
      background-color: $red;
      border-radius: 100px;
      padding: 15px 7px;
      margin-right: 10px;
      margin-left: -60px;
    }
  
    &:before{
      content: "";
      position: relative;
      float: left;
      display: block;
      width: 2px;
      height: 315px;
      left: 22px;
      z-index: -1;
      background-color: $red;
    }
  }
}