.alert {
  width: 100%;
  padding: 10px 16px;
  margin: 0;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  opacity: 1;
  display: flex;
  align-items: center;
  transition: opacity .2s;
  &--error {
    background-color: #fef0f0;
    color: #f56c6c;
  }
}