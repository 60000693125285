.btn {
	background: $primary-color;
	border: 1px solid $primary-color;
	border-radius: 3px;
	color: #fff;
	font-weight: bold;
	font-size: 14px;
	display: inline-block;
	text-decoration: none;
	cursor: pointer;
	line-height: normal;
	padding: 9px 14px;
	position: relative;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

.btn-back {
  color: #909498;
  font-size: 12px;
  text-decoration: none;
  font-weight: 500;
  display: inline-block;
  &:hover {
    opacity: 0.7;
  }
  i {
    margin-right: 5px;
    position: relative;
    top: 0;
    font-size: 9px;
  }
}