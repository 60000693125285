.error{
  margin-bottom: 50px;
  background-image: url(/static/img/errors/background.jpg);
  background-size: cover;

  &-status{
    width: 600px;
  }

  &-round{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-family: OpenSans-Regular;
    font-size: 50px;
    color: #fff;
    width: 400px;
    height: 400px;
    margin-left: 100px;
    background-color: $red;
    border-radius: 100%;
    margin-bottom: 4vw;
  }

  &-code{
    font-family: OpenSans-Bold;
    font-size: 180px;
    margin-top: -40px;
    margin-bottom: -120px;
  }

  &-message{
    width: 600px;
    color: $red;
    text-align: center;
    font-size: 20px;
    font-family: Roboto;
    font-weight: 500;
  }

  &-options-list{
    color: $text-color;
    font-weight: 400;


  }
}

@media screen and (max-width: 1024px) {
  .error{
    width: calc(100vw - 30px);
    margin-left: 15px;
    background-image: none;

    &-icon{
      color: #aeb4bf;
      font-size: 90px;
      position: absolute;
      top: 0;
    }

    &-status{
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-wrap: wrap;
    }

    &-round{
      background-color: transparent;
      border-radius: 0;
      width: 60vw;
      height: 30px;
      font-size: 50px;
      margin: 0 auto;
      position: relative;
    }

    &-message{
      position: relative;
      bottom: -50px;
      width: 80%;
      font-size: 16px;
      margin-top: 25px;
      color: #393939;
    }

    &-code{
      font-size: 36px;
      color: #aeb4bf;
      margin: 0 0 0 0;
      position: absolute;
      top: 25px;
    }

    &-options-list{
      margin-top: 20px;

      li{
        color: #aeb4bf;
      }
    }
  }
}