.mb-0 {
  margin-bottom: 0 !important;
}

.mb-2 {
  margin-bottom: 20px !important;
}

.scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #dcdcdc;
    border-radius: 3px;
  }
}

.rotate-up {
  transform: rotate(0deg);
}

.rotate-right {
  transform: rotate(90deg);
}

.rotate-down {
  transform: rotate(180deg);
}

.rotate-left {
  transform: rotate(-90deg);
}

.horizontal-dashed{
  width: 77%;
  border-top: 2px dashed white;
  border-bottom-width: 0px;
  position: absolute;
  top: 25%;
  z-index: 1;
}