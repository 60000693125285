.c-checkbox {
  user-select: none;
  cursor: pointer;
  .icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    color: #b2b4b3;
    // mask-image: url(~src/img/svg/icon-unchecked.svg);
    // mask-repeat: no-repeat;
    // mask-position: center center;
    // mask-size: contain;
    // &.checked {
    //   mask-image: url(~src/img/svg/icon-checked.svg);
    //   background: #ca1226;
    // }
  }
  &__title {
    padding-top: 0px;
    word-break: break-all;
  }
  &.disabled {
    color: #ababab;
    cursor: not-allowed;
    .icon {
      color: #ddd;
    }
  }

  @media (max-width: 1024px) {
    .icon {
      width: 22px;
      height: 22px;
      // mask-image: url(~src/img/svg/icon-unchecked-round.svg);
      // &.checked {
      //   mask-image: url(~src/img/svg/icon-checked-round.svg);
      // }
    }
    &__title {
      padding-top: 5px;
    }
  }
}