.documents{
  padding: 0px 75px 0px 96px;

  .row{
    margin-left: 0;
    margin-right:0;
  }
 
  &-title{
    font-family: Roboto;
    font-weight: 500;
    font-size: 28px;
    margin-bottom: 40px;
  }

  &-container{

  }

  &-nav{
    background-color: rgba(244, 244, 244, 0.43);
    padding: 20px 20px !important;
    height: fit-content;

    &-list{
      padding: 20px;

    }

    &-item{

    }

    &-link{

    }
  }

  &-box{
    padding-left: 50px !important;

    &-icon{
      font-size: 3rem;
      margin-right: 15px;
      color: $red;
    }

    &-title{
      font-family: Roboto;
      font-size: 1.6rem;
      font-weight: 500;
      color: $text-color;
      margin-bottom: 30px;
    }

    &-filename{
      line-height: 3rem;
      font-family: Roboto;
      font-weight: 400;
      font-size: 1.2rem;
    }

    &-link{
      text-decoration: none;
      color: #6f6f6f;
      font-family: Roboto;
      font-size: 0.9rem;
      font-weight: 400;

      &.instructions{
        display: block;
        margin-top: 30px;
      }

      &-icon{
        margin-right: 10px;
      }
    }
  }
  
  &-table{
    width: 100%;
    height: 25vw;
    overflow: scroll;

    &-row{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      border-bottom: solid 1px #e5e6e8;
      margin-top: 10px;
    }

    &-namecell{
      display: flex;
    }

    &-linkcell{

    }
  }

}

@media screen and (max-width: 1024px) {
  .documents{
    padding: 70px 0 0 0;
  
    .row{
      margin-left: 0;
      margin-right:0;
    }
   
    &-title{
      font-family: Roboto;
      font-weight: 500;
      font-size: 28px;
      margin-bottom: 40px;
      margin-left: 5vw !important;
    }
  
    &-container{
  
    }
  
    &-nav{
      background-color: rgba(244, 244, 244, 0.43);
      padding: 20px 20px !important;
      height: fit-content;
  
      &-list{
        padding: 20px;
  
      }
  
      &-item{
  
      }
  
      &-link{
  
      }
    }
  
    &-box{
      padding-left: 0!important;
  
      &-icon{
        font-size: 3rem;
        margin-right: 15px;
        color: $red;
      }
  
      &-title{
        font-family: Roboto;
        font-size: 1.4rem;
        font-weight: 500;
        color: $text-color;
        margin-bottom: 30px;
      }
  
      &-filename{
        line-height: 1;
        font-family: Roboto;
        font-weight: 400;
        font-size: 1.2rem;
        margin: 0;
        padding-right: 5px;
        word-break: break-all;
      }
  
      &-link{
        text-decoration: none;
        color: #6f6f6f;
        font-family: Roboto;
        font-size: 0.9rem;
        font-weight: 400;
  
        &.instructions{
          display: block;
          margin-top: 30px;
        }
  
        &-icon{
          margin-right: 10px;
        }
      }
    }
    
    &-table{
      width: 100%;
      height: 25vw;
      overflow: scroll;
  
      &-row{
        display: flex;
        width: 84vw;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        border-bottom: solid 1px #e5e6e8;
        margin-top: 10px;
      }
  
      &-namecell{
        width: 100%;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
      }
  
      &-linkcell{
  
      }
    }
  
  }
}