#fullpage{
  height: calc(100% - 7vw) !important;
}
.stocks{
  flex-wrap: wrap;
  min-height: 50vh;
  padding: 2% 4.1% 3.18% 5%;

  &.news{
    padding: 2vw 7vw 3.2vw 5vw;
    @media screen and (min-width:1600px) {
     padding: 51px 100px 80px 80px;
    }
  }
}
.stocks__title{
  font-family: Roboto;
  font-weight: 500;
  font-size: 1.2vw;
  margin-bottom: 1.5vw;
}
.stocks__container{
  width:100%;
  min-height: 25vw;
  &.flex{
    display: flex;
    justify-content: space-between;
  }
  
}
.stocks__container__list.news{
  width: 102.3%;
  display: flex;
  justify-content:flex-start;
  flex-wrap: wrap;
}
.stocks__container__list{
  display: flex;
  justify-content:space-between;
  flex-wrap: wrap;
}
.stocks__container__list__item{
  display: inline-block;

  &:last-of-type{
    
    .promo{
      margin-right: 0;
    }
  }
}
.overlay-link{
  text-decoration:none;
  color: #393939;
}
.promo{
  display:block;
  position: relative;
  background-repeat: round;
  border-radius:5px;
  width: 28vw;
  max-width: 460px;
  max-height: 420px;
  height: 22vw;
  margin-bottom: 60px;
  margin-right: 1.9vw;
  
  &.news{
    width: 28vw;
    max-width: none;
    max-height: none;
  }
}
.promo:hover .overlay{
  opacity: 1;
}
.text-board{
  position: relative;
  text-align: left;
  padding: 20px 32px;
  bottom: -30%;
  font-family: Roboto;
  color: #fafafa;
  border-radius: 5px;
}
.text-board__title{
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0.8vw;
  width: 100%;
  
}
.text-board__anons{
  margin-bottom:0.5vw;
  font-size: 16px;
}
.text-board__date{
  font-size: 14px;
  color: #ababab;
}

.overlay-link{

  .promo{
    margin-right: 0;
  }
}

.overlay{
  transition: opacity 1s ease-in-out;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
  position: absolute;
  height: 45%;
  width: 100%;
  opacity: 0.6;
  bottom: 0;
  border-radius:5px;

  .text-board{
    position: absolute;
    bottom: 0;
  }
}
.stocks__image-box{
  max-width: 43.2vw;
  @media screen and (min-width:1600px) {
    max-width: 640px;
  }
}
.stocks__inner-image{
  max-width: 43.2vw;
  max-height: 24.3vw;
  min-width: 43.2vw;
  border-radius: 6px;

  @media screen and (min-width:1600px) {
    max-width: 640px;
    min-width: 640px;
  }
}
.stocks__text {
  max-width: 35vw;
  font-size: 15px;
  text-align: justify;
  line-height: 30px;
  font-weight: 500;

  @media screen and (min-width:1600px) {
    max-width: 675px;
  }

}
.stocks__link-box{
  display: flex;
  margin-top: 1.3vw;
  width: 100%;
  justify-content: flex-end;
}
.backlink{
  font-family: Roboto;
  padding-top: 6px;
  padding-bottom: 6px;
  color:#393939;
  line-height: 40px;
  text-decoration:none;
  font-size:18px;
  cursor: pointer;
}
.link-form{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.arrow-icon{
  height: 12px;
}
.view-more{
  position: relative;
  padding: 1px 14px;
  top: 20px;
  left: 32px;
  height: 28px;
  border-radius: 3px;
  background-color: #ffffff;
  font-size: 14px;
  border:none;
  cursor: pointer;
  box-shadow: 0 0 9px 1px rgba(121, 132, 172, 0.18);
}

@media screen and (max-width: 1024px) {
  #fullpage{
    height: max-content !important;
    margin-bottom: 60px;
  }
  .stocks{
    flex-wrap: wrap;
    max-height: unset;
    min-height: 50vh;
    padding: 100px 0 0 5vw;
    margin: 0;
    width: 95%;
  
    &.news{
      padding: 0px 0 0 5vw;
      margin: 0;
      margin-bottom: 50px;
      width: 95%;
      height: max-content;
    }
  }
  .stocks__title{
    font-family: Roboto;
    font-weight: 500;
    font-size: 1.5rem;
    margin-bottom: 25px;
  }
  .stocks__container{
    width: 90vw;
    min-height: 25vw;
    height: auto;
  }
  .stocks__container__list.news{
    width: 100%;
    display: flex;
    justify-content:space-between;
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  .stocks__container__list{
    display: flex;
    justify-content:space-between;
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  .stocks__container__list__item{
    display: inline-flex;
    width: 100%;
    margin-bottom: 25px;
    justify-content: center;
  }
  .overlay-link{
    text-decoration:none;
    color: #393939;
  }
  .promo{
    display:block;
    position: relative;
    background-size: cover;
    background-position: 0;
    border-radius:5px;
    width: 90vw;
    height: 70vw;
    margin: 0;
  }
  .promo:hover .overlay{
    opacity: 1;
  }
  .text-board{
    position: absolute;
    text-align: left;
    padding: 10px 30px;
    bottom: 5px;
    font-family: Roboto;
    color: #fafafa;
    border-radius: 5px;
  }
  .text-board__title{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0.8vw;
    width: auto;
  }
  .text-board__anons{
    margin-bottom:0.5vw;
    font-size: 16px;
  }
  .text-board__date{
    font-size: 14px;
    color: #ababab;
  }
  .overlay{
    transition: opacity 1s ease-in-out;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    position: absolute;
    height: 35vw;
    width: 100%;
    opacity: 1;
    bottom: 0;
    padding: 0px;
    border-radius:5px;
  }
  .stocks__image-box{
    position: absolute;
    left: 49.8vw;
    top: 11.6vw;
    max-width: 43.2vw;
  }
  .stocks__inner-image{
    max-width: -webkit-fill-available;
    max-height: fit-content;
    width: 95vw;
    margin-left: 0vw;
    margin-bottom: 20px;
    min-width: 43.2vw;
    border-radius: 0;
  }
  .stocks__text {
    text-align: left;
    max-width: 90vw;
    font-size: 15px;
    line-height: 2;
    font-weight: 500;
    height: auto;
    color: #6f6f6f;
  }
  .stocks__link-box{
    display: flex;
    margin-top: 1.3vw;
    width: 100%;
    justify-content: flex-end;
  }
  .backlink{
    font-family: Roboto;
    padding-top: 6px;
    padding-bottom: 6px;
    color:#393939;
    line-height: 40px;
    text-decoration:none;
    font-size:18px;
    cursor: pointer;
  }
  .link-form{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .arrow-icon{
    height: 12px;
  }
  .view-more{
    display: none;
    position: relative;
    top: 20px;
    left: 32px;
    height: 24px;
    border-radius: 3px;
    background-color: #ffffff;
    font-size: 14px;
    border:none;
    cursor: pointer;
  }
}