$font-sizes: (0, 10, 12, 14, 16, 18, 20, 22, 24);


@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $size in $font-sizes {
      .f#{$infix}-#{$size} {
        font-size: #{$size}px !important;
        line-height: 1.3em !important;
      }
    }
  }
}

.lh-0 {
  line-height: 0 !important;
}
.lh-1 {
  line-height: 1em !important;
}
.lh-1-4 {
  line-height: 1.4em !important;
}

h3 {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.8;
  letter-spacing: normal;
}

h4 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
}

h5 {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
}

strong { font-weight: 500; }

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .fw#{$infix}-300 { font-weight: 300 !important; }
    .fw#{$infix}-400, .fw#{$infix}-normal   { font-weight: normal !important; }
    .fw#{$infix}-500   { font-weight: 500 !important; }
    .fw#{$infix}-bold {font-weight: bold !important; }
  }
}

@mixin text-emphasis-variant-br($name, $color) {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      .text#{$infix}-#{$name} {
        color: $color !important;
      }
    }
  }
}

@each $color, $value in $theme-colors {
  @include text-emphasis-variant-br($color, $value);
}