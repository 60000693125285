.shadow-box {
  @media (max-width: 1024px) {
    border-radius: 4px;
    box-shadow: 0 0 9px 1px rgba(121, 132, 172, 0.18);
    background-color: #ffffff;
    padding: 15px 20px;
    border: 1px solid transparent;

    &.active {
      border: 1px solid #ffb4bc;
    }
  }
}