.left-5{
  left: 5vw !important;
}
.section{

  &.active.fp-completely{
    z-index:1!important;
  
  }

  &.fp-completely{
    z-index:-1!important;
  
  }


  &.del{

    &:first-of-type{

      .fp-tableCell{
        display: block;
      }
    }
  }

}
.mar-b{
  margin-bottom: 30px !important;
}
.mar-t{
  margin-top: 30px !important;
}

.in{
  display: inline !important;
}

.screen3pl{
  display:block;
  height: 82%;
  width: 90vw;
  margin-left: 5vw;
  margin-bottom:9%;
  z-index:1;
  
  &.first{
    background:url(/static/img/3pl/3PL_1.jpg);
    background-size: cover;

    &.express{
      background-image: url(/static/img/express/exp-1.jpg);
      height: 39vw;
      margin-bottom: 120px;
    }
  
  }

  &.second{
    background:url(/static/img/3pl/3PL_2-new.jpg);
    margin-top: 3vw;
    justify-content: center;
    right: 9.5vw;
    top: 2.3vw;
    height: 54.4%;
    width: 30.9%;
    background-size: cover;
    position: absolute;
    background-clip: border-box;
    -webkit-transform: scale(1.35);
    transform: scale(1.35);
  
  }

  &.third{
    justify-content:center;
  
  }

  &.fourth{
  
  }

  &.fifth{
    background:url(/static/img/3pl/3PL_3.jpg);
    background-size: cover;
    height:19vw;
    margin-top:5px;
  
  }

}

.textbox{
  display:flex;
  flex-wrap:wrap;
  position:relative;
  top:3vw;
  text-align:center;
  justify-content:center;
  color:#000;
  width:40vw;
  left:calc(50% - 20vw);
  line-height: 1.1;
  
  &__title{
    font-size:1.7vw;
    font-family:OpenSans-Regular;
  
  }
  
  &__border{
    padding-left: 1.5vw;
    margin-top:0.6vw;
    margin-bottom:2vw;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    line-height: 1;
    font-family:SourceSansPro-Light;
    font-size: 48px;
    border-left:3px solid $red;
    text-align:center;
    color:#000;

    &-3pl{
      border:none;
      width:fit-content;
      padding-left: 96px;
      text-align: left;
      margin-bottom: 0px;
      font-size: 1.9vw;

    }

    &.exp{
      padding-left: 30px;
      padding-top: 15px;
      padding-bottom: 15px;
      margin-top: 0;
    }
  
  }
  
  &__span{
    font-size:1.1vw;
  
  }
  
}

.noborder{
  display:flex;
  width:35vw;
  margin-left:calc(50% - 17.5vw);
  align-items:center;
  margin-top:1.2vw;
  margin-bottom:2.2vw;
  font-family:OpenSans-Light;
  font-size:2vw;
  color:#000;
  border:none;
}

.bold{
  display:block;
  font-family:Avanti-Bold;
  color: $red;

  &.white{
    color:#fff; 
  }
  &.inline{
    display: inline-block;
  }
}

ul.relations{
  display:flex;
  width: 90%;
  margin-left: 5%;
  justify-content:space-between;
  margin-top: 1.5vw;
  margin-bottom: 0;

  &.pl-fifth{
    margin-bottom: 20px;
  }

  &-block{
    display: block;
    padding-left: 5vw;
    margin-top: 1.5vw;
    margin-bottom: 1.5vw;

    .relations__item{
      display: block;
      font-size: 16px;
      margin-bottom: 5px;
    }
  }
  
  .relations__item{
    display:inline-block;
    font-size:16px;
    color:#000;
    margin-bottom:0;

    &.pl{
      width:auto;
      margin-top: 0;
      padding-left: 36px;

      &.express{
        width: 47% !important;
      }

      .item{
        margin-left: -36px;
      }
    }
  }
}

.bottom-text{
  padding-top:0;
  text-align:center;
  font-family:OpenSans-Regular;
  font-size:16px;
  color:#c1c1c1;
  width:680px;
  margin-left: 4.8vw;

  &.therm{
    padding-top: 20px;
    color: #393939;
    width: 50vw;
    margin-left: 25vw;
    line-height: 1.5;
  }
}

.advantages{
  display:flex;
  align-items:flex-start;
  justify-content:space-between;
  width:50vw;
  margin-left:calc(50% - 25vw);

  &__item{
    font-family:OpenSans-Regular;
    width:220px;
    display:inline-block;
    line-height: 1;

    .bigtext{
      width:220px;
      font-size:2vw;
      display:block;
      text-align:center;
    }
    
    .mintext{
      font-size:1vw;
      width:220px;
      margin-top: 30px;
      text-align:center;
    }
  }
  
}

.red_arrows{
  width: 20vw;
  margin-left: calc(50% - 10vw);
  margin-bottom: 4vw;
  margin-top: 2vw;

  &.exp{
    margin-left: 12vw;
  }

  &.int{
    width: 15vw;
    margin-left: calc(50% - 7.5vw);
    margin-bottom: 2vw;
  }
}

a.economy:hover{
  color: #ffffffad!important;
  background-color: #e8041c !important;
  cursor: pointer;
}

.economy{
  border:none;
  text-decoration: none !important;
  background-color:$red;
  display:flex;
  flex-wrap:wrap;
  text-align:center;
  justify-content:center;
  align-items:center;
  font-size:1.5vw;
  height: 4.5vw;
  width: 35vw;
  margin-left: calc(50% - 17.5vw);
  font-family:OpenSans-Light;
  margin-top:3vw;
  margin-bottom: 1vw;
  color:#fafafa !important;

  &-list1{
    margin-top: 0.5vw
  }

  &-list2{
    margin-bottom: 3vw;
    
    li{
      text-align: left;
    }
  }

  &.pl-fifth{
    margin-bottom: 40px;

    &:hover{
      background-color: #e8041c;
    }
  }

  p{
    margin: 0;
  }

  &.exp{
    margin-top: 4vw;
    color: $text-color;
    width: 70vw !important;
    margin-left: 15vw !important;
    height: 160px!important;
    justify-content: space-around;
    cursor: default !important;

    ul li{
      font-family: Roboto;
      width: 180px;
      font-weight: 500;
      line-height: 25px;
    }
  }

  ul{
    width:100%;
    display:flex;
    justify-content:space-around;
    position: relative;
  
    li{
      display:inline-block;
      text-align:center;
      font-size:1vw;
      width: 15.5vw;
      z-index: 2;
    }
  }
  
  .number{
    height:140px;
    width:44px;
    font-size:3vw;
    padding:0.4vw 1.8vw;
    font-family:SourceSansPro-Light;
    color:$red;
    background-color:#fafafa;
    border-radius:100%;
    line-height:1;

    &.exp{
      background-color: $red;
      color: #fff;
    }
  }
}

.item{
  margin-right:16px;
  transform:translateY(-2.5px);
  width:16px;
  height:16px;

  &2{
    width:16px;
    height:16px;
    margin-right:10px;
    transform:translate(20px);
  }
}

.requirements{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -30px;

  ul{
    margin-top: 50px;
    min-width: 75%;

    li{
      display: inline-block;
      width: 49%;
      font-size: 15px;
      line-height: 20px;
      font-family: Roboto;
    }
  }
}

@media screen and (max-width: 1024px) {
  span.mobile, p.mobile, .textbox__mintext{
    display: none !important;
  }

  #fullpage{
    margin-top: 0;
  }

  .section{
    // margin-bottom: 70px;

    &.active.fp-completely{
      z-index:1!important;
    
    }
  
    &.fp-completely{
      z-index:-1!important;
    
    }
  
  }
  
  .in{
    display: inline !important;
  }
  
  .screen3pl{
    display:block;
    height: fit-content;
    width: 90vw;
    margin-left: 5vw;
    margin-bottom:9%;
    z-index:1;
    position: unset;
    
    &.first{
      background:url(/static/img/3pl/3PL_1.jpg);
      background-size: cover;
      height: 45vw;
      width: 100vw;
      margin-left: 0;

      .textbox{
        margin-bottom: 10vw;
        top: 3vw;
      }
  
      &.express{
        background-image: url(/static/img/express/exp-1.jpg);
        height: 39vw;
        margin-bottom: 50px;
      }
    
    }
  
    &.second{
      background:url(/static/img/3pl/3PL_2-new.jpg);
      margin-top: 3vw;
      justify-content: center;
      right: 0;
      top: 0;
      height: 25%;
      background-position-y: 289px;
      background-position-x: -39px;
      position: unset;
      width: 100vw;
      background-size: 125%;
      background-clip: border-box;
      transform: none;
      margin-left: 0;
    
    }
  
    &.third{
      justify-content:center;

      .bottom-text{
        width: 84vw;
        margin-left: 3vw !important;
      }

      .textbox{

        &__border{
          width: 270px;
          margin: 0 auto!important;
          padding-left: 4vw !important;
          text-align: left;
        }
      }
    
    }
  
    &.fourth{
      .textbox{
        &__border{
          width: 310px !important;
          margin:0 0 30px !important;
          padding-left: 2vw;
          text-align: left;
        }
      }

      .economy{
        width: 100vw !important;
        height: 350px !important;
        margin-left: -5vw !important;

        ul{ 
          display: inline-block;
          width: 80%;
          height: 100%;

          &:first-of-type{
            width: 20%;
            padding-top: 5%;

            li{
              text-align: center;
            }
          }
        }
        li{
          display: block;
          height: 30%;
          font-size: 16px;
          width: 100% !important;
          text-align: left;
        }

        .number{
          font-size: 30px;
          padding: 5px 16px;
        }

      }

      .bottom-text{
        width: 100vw !important;
        margin-left: -5vw !important;
      }
    }
  
    &.fifth{
      background:url(/static/img/3pl/3PL_3.jpg);
      background-size: cover;
      background-position-x: -100vw;
      height: 50vw;
      width: 100vw;
      margin: 0;
      margin-top: 30px;
    
    }
  
  }
  
  .textbox{
    display:flex;
    flex-wrap:wrap;
    position:relative;
    top:-20px;
    text-align:center;
    justify-content:center;
    color:#000;
    width:100vw;
    left:0;
    line-height: 1;

    &.exp{
      margin-left: 0 auto !important;
    }
    
    &__title{
      font-size:16px;
      font-family:OpenSans-Regular;
    
    }
    
    &__border{
      padding-left: 1.5vw;
      margin-top: 6vw;
      margin-bottom: 2vw;
      margin-left: 9vw;
      margin-right: 9vw;
      line-height: 1.5;
      font-family: SourceSansPro-Light;
      font-size: 16px;
      border-left:2px solid $red;
      text-align:center;
      color:#000;

      &.pl-fifth{
        width:245px !important;
        margin-left: 3vw !important;
        text-align: left

      }
  
      &-3pl{
        border:none;
        width:fit-content;
        padding-left: 0;
        text-align: left;
        margin: 0 auto;
        font-size: 1.9vw;
  
      }
  
      &.exp{
        padding-left: 10px;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-left: 3vw !important;
        text-align: left;
      }
    
    }
    
    &__span{
      font-size: 12px;
      width: 220px;
      margin: 0 auto;
      line-height: 1.3;
      color: #848383;
      margin-top: 30px;
      margin-bottom: 20px;
    
    }
    
  }
  
  .noborder{
    display: block;
    text-align: center;
    width: 80vw;
    margin: 2vw auto;
    align-items:center;
    font-family:OpenSans-Light;
    font-size:18px;
    color:#000;
    border:none;

    &.widther{
    }
  }
  
  .bold{
    display:block;
    font-family:Avanti-Bold;
  }
  
  ul.relations{
    display:block;
    width: 90%;
    margin-left: 5%;
    justify-content:space-between;
    margin-top: 10px;
    margin-bottom: 40px;

    .pl-fifth{

    }
  
    &-block{
      width: 90%;
      margin-left: 5%;
      margin-top: 10px;
      margin-bottom: 40px;
  
      .relations__item{
        display: block;
        margin-bottom: 0.75vw;
      }
    }
    
    .relations__item{
      width: 100% !important;
      display:block;
      font-size:16px;
      color:#000;
      margin-bottom:12px;
      padding-left: 20px !important;

      &:last-of-type{
        margin-bottom: 40px;
      }
    }
  }
  
  .bottom-text{
    padding-top:0;
    text-align:center;
    font-family:OpenSans-Regular;
    font-size:14px;
    color:#c1c1c1;
    width:100vw;
    margin-left: 0;
  
    &.therm{
      padding-top: 20px;
      color: #393939;
      width: 90vw;
      text-align: left;
      margin-left: 5vw;
      line-height: 1.5;
    }
  }
  
  .advantages{
    display:block;
    width: 90vw;
    margin-left: 0;
  
    &__item{
      font-family:OpenSans-Regular;
      margin-left: 5%;
      width:90%;
      display:inline-block;
  
      .bigtext{
        width:100%;
        font-size:30px;
        display:block;
        text-align:center;
      }
      
      .mintext{
        font-size:20px;
        width:100%;
        text-align:center;
      }
    }
    
  }
  
  .red_arrows{
    width: 40vw;
    margin-left: 25vw;
    margin-top: 20px;
    margin-bottom: 2vw;
  
    &.exp{
      width: 50vw;
      margin-left: 25vw;
      margin-bottom: 50px;
    }
  }
  
  .economy{
    border:none;
    text-decoration: none;
    background-color:$red;
    display:flex;
    flex-wrap:wrap;
    text-align:center;
    justify-content:center;
    align-items:center;
    font-size: 18px;
    height: 11vw;
    width: 100vw;
    margin-left: -5vw;
    font-family:OpenSans-Light;
    margin-bottom:20px;
    color:#fafafa;

    &.pl-fifth{
      width: 90% !important;
      margin-left: 5% !important;
      margin-top: 0px;
      height: 15vw !important;
      font-size: 20px !important;
    }
  
    p{
      margin: 0;
    }
  
    &.exp{
      display: flex;
      align-items: flex-start;
      margin-top: 4vw;
      color: $text-color;
      width: 100vw !important;
      margin-left: 0 !important;
      height: auto !important;
      justify-content: space-between;
      cursor: default !important;
  
      ul {
        &.mobile{
          display: inline-block !important;
        }
        width: 50% !important;

        &:first-of-type{
          width: 15% !important;
        }
        
        li{
          font-size: 14px;
          font-family: Roboto;
          width: 100% !important;
          height: 75px;
          font-weight: 500;
          line-height: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  
    ul{
      width:100%;
      display:flex;
      justify-content:space-evenly;
    
      li{
        display:inline-block;
        text-align:center;
        font-size:1vw;
        width: 15.5vw;
      }
    }
    
    .number{
      height:140px;
      width:44px;
      font-size:3vw;
      padding:0.4vw 1.8vw;
      font-family:SourceSansPro-Light;
      color:$red;
      background-color:#fafafa;
      border-radius:100%;
      line-height:1;
  
      &.exp{
        background-color: $red;
        font-size: 30px;
        padding: 10px 18px;
        color: #fff;
        height: fit-content;
        width: fit-content;
      }
    }
  }
  
  .item{
    margin-right:5px;
    transform:translateY(-2.5px);
    width:16px;
    height:16px;
    margin-left: -25px !important;
  
    &2{
      width:16px;
      height:16px;
      margin-right:10px;
      transform:translate(20px);
    }
  }
  
  .requirements{
    width: 90vw;
    display: flex;
    justify-content: center;
    margin: 5vw;
    border: solid 1px #ca1226;
    padding: 20px;
  
    ul{
      margin-top: 10px;
  
      li{
        display: block;
        width: 100%;
        font-size: 15px;
        line-height: 20px;
        font-family: Roboto;
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .screen3pl{
    &.fifth{
      background: url(/static/img/3pl/3PL_3.jpg);
      background-size: cover;
      height: 15vw;
      background-position-y: 0;
      margin-top: 5px;
    }
  }

  .textbox{

    &__border{
      padding-left: 1.5vw;
      margin-top: 1vw;
      margin-bottom: 2vw;
      line-height: 1;
      font-family: OpenSans-Light;
      font-size: 2.2vw;
      border-left: 3px solid #ca1226;
      text-align: center;
      color: #000;
    }
  }

  ul.relations{
    
    &.pl-fifth{
      margin-bottom: 0;
    }

    &-block{
      display: block;
      padding-left: 4.8vw;
      margin-top: 0.5vw;
      margin-bottom: 0.5vw;
    }
  }

  .bottom-text{
    padding-top: 0.7vw;
    text-align: center;
    font-family: OpenSans-Regular;
    font-size: 15px;
    color: #c1c1c1;
    width: 550px;
    margin-left: 4.8vw;

    &.therm{
      padding-top: 20px;
      color: #393939;
      width: 50vw;
      margin-left: 25vw;
      line-height: 1.5;
      font-size: 14px;
    }
  }

  .screentherm{

    &.first{

      height: 18vw !important;
      background-position-y: -30px;
    }
  }

  .fp-tableCell{
    .footer{
      margin-left: 4.5%;

      &__feedback{
        height: 240px;
        background-color: #ececec;
        width: 91%;
        padding-top: 25px;
        margin-top: 0px;
        margin-left: 4.5%;
      }
    }
  }
}

@media screen and (min-width:1024px) and (max-width:1600px) {
  .screen3pl{
    .second{
      height: 39.4%;
      width: 26.9%;
    }
  }

  .screendel{
    .fourth{
      height: 59.4%;
    }
    .second{
      height: 62.4%;

      .about{
        height: 33.4vw;
        width: 38.9vw;

        &-3{
          height: 50.4%;
          background-position-y: 0;
          width: 39.9%;
        }
        &-4{
          height: 33.4vw;
          width: 38.9vw;
        }
      }
    }
  }

    .textbox{
      font-size: 2.5vw !important;

      &__border{
        font-size: 2.5vw !important;
      }
    }
}