.order-path {
  .circle {
    width: 10px;
    height: 10px;
    border: solid 1px #6f6f6f;
    border-radius: 50%;
  }
  .path {
    width: 100%;
    height: 0px;
    border-top: 1px dashed #6f6f6f;
    margin-top: 4px;
  }
}