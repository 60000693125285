.va {
  // white-space: nowrap;
  &:before {
    content: '';
    height: 100%;
    width: 0;
  }
  & &m, &:before {
    display: inline-block;
    vertical-align: middle;
    // white-space: normal;
  }
}
.fa {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  > * {
    // ali
  }
}

sup {
  vertical-align: super;
  font-size: smaller;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.hoverable {
  cursor: pointer;
}

.p-relative {
  position: relative;
}
.p-static {
  position: static !important;
}

.flex-shrink {
  flex-shrink: 1;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.separator {
  border-bottom: 1px dotted rgba(0, 0, 0, .25);
  &.solid {
    border-bottom: 1px solid #e0e3eb;
  }
}

.noscroll {
  overflow: hidden;
  // position: fixed;
}

.status-dot {
  display: inline-block;
  width: 8px !important;
  height: 8px !important;
  background: $text-muted;
  border-radius: 50%;
  &.status-success {
    background: #54cdb3;
  }
  &.status-error {
    background: #ff5b5c;
  }
}

body.no-scroll {
  @media (max-width: 768px) {
    height: 0 !important;
    overflow: hidden !important;
  }
}