b{
  font-weight: 600;
}
.lh{
  line-height: 1;
}

.doubled{
  font-size: 1vw !important;
  width: 11vw !important;
  height: 3vw !important;
  margin: 0 auto !important; 
}

.group{

  &-img{
    // display: inline-block;
    max-width: 100%;
    min-width: 18%;
    height: 100%;
  }

  &-container{
    display: flex;
    width: 23%;
    height: 18%;
    margin: 0.8vw;
    align-items: center;
    justify-content: center;
  }

  &-box{
    width: 80%;
    height: 50%;
    margin: 5vw auto 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

  }
}

.screendel{

  &.first{
    display:flex;
    flex-wrap:wrap;
    height: 28vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    width: 76vw;
    background-image:url(/static/img/delivery/group.jpg);
    background-size:cover;
    background-clip:border-box;
  }

  &.second{
    margin-top:0;
    background-image:url(/static/img/delivery/del-1.jpg);
    background-size:cover;
    position: absolute;
    top: 0;
    right: 3.6vw;
    height: auto;
    width: 47.9vw;
    bottom: 20%;
    background-position: top;
    background-clip: border-box;

    &.about{
      background-image: url(/static/img/about/about-2.jpg);
      top: 0;
      right: 3.6vw;
      height: auto;
      width: 44.9vw;
      bottom: 20%;
      background-position: top;

      &-3{
        background-image: url(/static/img/about/about-3.jpg);
        top: 0;
        right: 3.6vw;
        height: auto;
        width: 45.9vw;
        bottom: 20%;
        background-position: top;
      }

      &-4{
        background-image: url(/static/img/about/about-4.jpg);
        top: 0;
        right: 3.6vw;
        height: auto;
        width: 47.9vw;
        bottom: 20%;
        background-position: top;
      }
    }

    &.therm{
      background-image: url(/static/img/therm/therm-2.jpg);
      width: 43%;
      top: -2vw;
      right: 5vw;
      background-position: 0;
      height: 74%;
    }

    &.wrap{
      background-image: url(/static/img/wrap/wrap-1.jpg);
      right: 4.6vw;
      top: 1.2vw;
      height: auto;
      width: 37.9vw;
      bottom: 20%;
      background-position: center;
    }

    &.other{
      background-image: url(/static/img/other/other-1.jpg);
      right: 3.6vw;
      height: auto;
      width: 47.9vw;
      bottom: 20%;
      background-position: right;

      &-2{
        background-image: url(/static/img/other/other-2.jpg);
        right: 3.6vw;
        height: auto;
        width: 47.9vw;
        bottom: 20%;
        background-position: right;
      }

      &-3{
        background-image: url(/static/img/other/other-3.jpg);
        right: 3.6vw;
        height: auto;
        width: 43.9vw;
        bottom: 20%;
        background-position: right;
      }

      &-4{
        background-image: url(/static/img/other/other-4.jpg);
        right: 3.6vw;
        height: auto;
        width: 47.9vw;
        bottom: 20%;
        background-position: right;
      }
    }
  }
  &.third{
    background-image:url(/static/img/delivery/del-2.png);
    background-size: cover;
    position: absolute;
    right: 6.85vw;
    top: 5.2vw;
    height: 63.8%;
    width: 39.9%;
    background-clip: border-box;
    -webkit-transform: scale(1.35);
    transform: scale(1.35);

    &.exp{
      background-image: url(/static/img/express/exp-2.jpg);
      transform: none;
      top: 1.1vw;
      right: 3.6vw;
      height: auto;
      width: 42.9vw;
      bottom: 20%;
      background-position: right;
    }

    &.therm{
      background-image: url(/static/img/therm/therm-3.jpg);
      transform: none;
      height: 64.4%;
      top: 3.5vw;
      width: 39.9%;
      right: 5vw;
    }

    &.wrap{
      transform: none;
      background-image: url(/static/img/wrap/wrap-2.jpg);
      right: 4.6vw;
      bottom: 20%;
      background-position: right;
      width: 39.9vw;
      height: auto;
      top: 1.2vw;

      &-2{
        background-image: url(/static/img/wrap/wrap-3.jpg);
        right: 3.6vw;
        top: 1.2vw;
        transform: none;
        height: auto;
        width: 42.9vw;
        bottom: 20%;
        background-position: center;
      }

      &-3{
        background-image: url(/static/img/wrap/wrap-4.jpg);
        right: 3.6vw;
        height: auto;
        top: 1.2vw;
        width: 42.9vw;
        bottom: 20%;
        background-position: right; 
        transform: none;
      }
    }
  }
  &.fourth{
    position:absolute;
    background-image: url(/static/img/delivery/delivery-4.jpg);
    background-size: cover;
    top: 0;
    right: 3.6vw;
    height: auto;
    width: 47.9vw;
    bottom: 20%;
    background-position: top;
    background-clip:border-box;
  }
}
.section{
  .fp-tableCell{
    vertical-align:baseline;
  }
}
.del{
  .textbox{
    width:70vw;
    top:1vw;
    left:5%;

    &__border{
      margin:0;
      padding-left:1.2vw;
      line-height: 1.1;
      width:30vw;
      text-align:left;
      font-size: 48px;
    }
    &__image{
      display:inline;
      height:4vw;
    }
    &__mintext{
      margin-top: 1.2vw;
      margin-bottom: 0;
      font-size: 1.1vw;
      font-family: SourceSansPro-Light;
      line-height: 1.25;
    }

    &.center{
      left: 4%;
    }
  }
  .leftside-text{
    padding-top: 5px;
    width:28.5vw;
    text-align:left;
    font-size:1vw;
    line-height:1.1vw;
    margin-bottom: 0;
  }
  ul.relations{
    margin-left:5%;
    margin-top:3.5vw;
    display:block;

    .relations__item{
      display:block!important;
      margin-bottom:0.2vw;

      &.express{
        margin-bottom: 0.5vw;
      }
    }
  }
  .economy{
    margin-left: 5%;
    font-size:1vw;
    width:11vw;
    height:3vw;
    cursor:pointer;
    margin-top:2.5vw;

    &:hover{
      color: #ffffffad!important;
    }
  }
}

@media screen and (max-width: 1024px) {
  ul.express-list{
    display: inline-block !important;
  }

  .group{
    &-img{
      // display: inline-block;
      max-width: 100%;
      max-height: 100%
    }

    &-container{
      width: 21vw;
      display: flex !important;
      height: 20vw;
      align-items: center;
      margin: 0;
    }

    &-box{
      width: 80vw;
      height: auto;
      margin: 0 auto;
      display: flex ;
      flex-wrap: wrap;
      justify-content: space-around;

    }
  }
  b{
    font-weight: 600;
  }
  .lh{
    line-height: 1.5;
  }
  .screendel{
    
    &.first{
      display:flex;
      flex-wrap:wrap;
      height: 30vw;
      margin-left: 9%;
      margin-top: 2%;
      width: 82vw;
      background-image:url(/static/img/delivery/group.jpg);
      background-size:cover;
      background-clip:border-box;
    }
  
    &.second{
      background-image: url(/static/img/delivery/del-1.jpg);
      position: unset;
      height: 45vw;
      margin-bottom: 45px;
      width: 100vw;
      background-position-y: 70vw;
      transform: none;
  
      &.about{
        background-image: url(/static/img/about/about-2.jpg);
        height: 60vw;
        width: 100vw;
        margin: 40px 0 50px 0px;
        background-position: 0;
  
        &-3{
          background-image: url(/static/img/about/about-3.jpg);
          width: 100vw;
          height: 60vw;
          background-position: 0;
          margin: 40px 0 50px 0px;
        }
  
        &-4{
          background-image: url(/static/img/about/about-4.jpg);
          height: 60vw;
          width: 100vw;
          background-position: 0;
          margin: 40px 0 0px 0px;
        }
      }
  
      &.therm{
        background-image: url(/static/img/therm/therm-2.jpg);
        width: 100vw;
        background-position-y: 45vw;
        top: 6vw;
        right: 11vw;
        margin-bottom: 20px;
      }
  
      &.wrap{
        background-image: url(/static/img/wrap/wrap-1.jpg);
        right: 10.6vw;
        height: 75vw;
        width: 100vw;
        background-position: 0;
        margin-bottom: 45px;
      }
  
      &.other{
        background-image: url(/static/img/other/other-1.jpg);
        margin-bottom: 50px;
        width: 100vw;
        background-position-y: top;
        height: 60vw;
  
        &-2{
          background-image: url(/static/img/other/other-2.jpg);
          margin: 40px 0 50px 0px;
          width: 100vw;
          background-position-y: top;
          height: 60vw;
        }
  
        &-3{
          background-image: url(/static/img/other/other-3.jpg);
          margin: 40px 0 50px 0px;
          width: 100vw;
          background-position-y: top;
          height: 60vw;
        }
  
        &-4{
          background-image: url(/static/img/other/other-4.jpg);
          margin: 40px 0 50px 0px;
          background-position-y: -30vw;
          width: 100vw;
          background-position-y: top;
          height: 60vw;
        }
      }
    }
    &.third{
      background-image: url(/static/img/delivery/del-2.jpg);
      background-size: cover;
      position: unset;
      height: 60vw;
      background-position-y: -4vw;
      width: 85vw;
      margin-left: 15vw;
      margin-bottom: 50px;
      margin-top: 40px;
      background-clip: border-box;
      transform: none;
  
      &.exp{
        background-image: url(/static/img/express/exp-2.jpg);
        transform: none;
        width: 100vw;
        margin: 0;
        margin-bottom: 30px;
        background-position: 0;
        top: 7.1vw;
      }
  
      &.therm{
        background-image: url(/static/img/therm/therm-3.jpg);
        transform: none;
        height: 60vw;
        margin: 40px 0px 50px 0px;
        top: 7.5vw;
        width: 100vw;
        background-position: 0;
      }
  
      &.wrap{
        background-image: url(/static/img/wrap/wrap-2.jpg);
        right: 10.6vw;
        height: 60vw;
        width: 100vw;
        margin: 0;
        margin-bottom: 40px;
        background-position: 0;
  
        &-2{
          background-image: url(/static/img/wrap/wrap-3.jpg);
          right: 10.6vw;
          height: 50vw;
          width: 100vw;
          margin: 40px 0 50px;
          background-position: 0;
        }
  
        &-3{
          background-image: url(/static/img/wrap/wrap-4.jpg);
          right: 10.6vw;
          height: 50vw;
          width: 100vw;
          margin: 40px 0px 50px 0px;
          background-position: 0;
        }
      }
    }
    &.fourth{
      position:unset;
      background-image: url(/static/img/delivery/delivery-4.jpg);
      height: 60vw;
      width: 100vw;
      margin-bottom: 50px;
      margin-top: 40px;
      background-clip: border-box;
      transform: none;
    }
  }
  .section{
    .fp-tableCell{
      vertical-align:unset;
    }
  }
  .del{
    .textbox{
      top:1vw;
      width: 90vw;
      left: 0;
      margin: 0 auto;
      text-align:left;

      &.widther{
        width: 100vw;

        .textbox__mintext{
          width: 90%;
          margin: 0 auto;
        }
      }
  
      &__mintext{
        font-size:16px;
        line-height:1.15;
        margin-top:10px;
        font-family:SourceSansPro-Light;
        color: #0d0802;
      }
      
      &__image{
        height:30px;
        margin: auto 0;
        display:inline-block;
      }
  
      &__border{
        align-items:flex-start;
        width: 100% !important;
        margin:0 auto !important;
        padding-left:7px;
        text-align:left;
        font-size: 18px;

        &.first{
          width: 75% !important;
        }
      }
  
      &.center{
        left: 0;
      }
    }
    .leftside-text{
      width:90vw !important;
      margin: 40px auto;
      text-align:left;
      font-size:14px;
      line-height:1.1;
    }
    ul.relations{
      margin-left: 5%;
      margin-top: 30px;
      display: block;
      margin-bottom: 20px;
  
      .relations__item{
        display:block!important;
        margin-bottom:10px;

        &.price{
          margin-top: 20px;
        }
      }
    }
    .economy{
      width: 90%!important;
      margin-left: 5%!important;
      margin-top: 35px;
      height: 60px !important;
      font-size: 20px !important;
    }
  }
  
}