.c-radio {
  user-select: none;
  cursor: pointer;
  .icon {
    svg {
      fill: transparent;
      stroke-width: 2;
      stroke: #b2b4b3;
    }
    &.active {
      svg {
        fill: #ca1226;
        stroke: #ca1226;
      }
    }
  }
  &.disabled {
    color: #ababab !important;
    .c-radio__title {
      color: inherit !important;
    }
    cursor: not-allowed;
    .icon {
      svg {
        fill: transparent;
        stroke: #ddd;
      }
    }
  }

  @media (max-width: 1024px) {
    .icon {
      display: inline-block;
      width: 22px;
      height: 22px;
      background: #b2b4b3;
      mask-image: url(~src/img/svg/icon-unchecked-round.svg);
      mask-repeat: no-repeat;
      mask-position: center center;
      mask-size: contain;
      svg {
        display: none;
      }
      &.active {
        background: #ca1226;
        mask-image: url(~src/img/svg/icon-checked-round.svg);
      }
    }
    &__title {
      padding-top: 5px;
    }
  }
}