@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .content{
    .content__list{
      .item{
        .text-box{
          width: 25vw;
        }
      }
    }
  }
  .bld{
    .textbox{
      width: 70vw !important;
    }
    .textbox.left{
      width: 37vw !important;
    }
  }
  .del{
    .textbox{
      width: auto !important;
      max-width: 37%;

      &.center{
        width: 72vw !important;
        max-width: none;
      }
    }
  }
  .screen3pl{
    &.first{
      .textbox{
        .textbox__border{
          width: 70%;
          margin: 1vw 0;
        }

        .textbox__title{
          width: 100%;
        }
      }
    }
  }
  @media screen and (max-width:1600px) {
    .header__left__phone__oldsite{
      margin-top: 20px 
    }
  }
}