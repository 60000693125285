.labeled {
  display: block;
  vertical-align: top;
  // min-width: 100px;
  position:relative;
  padding: 7px 0 0 0;
  &__cont {
    min-height: 35px;
    border-radius: 3px;
    border: solid 1px #d7d7d7;
    // padding: 5px 0 0 12px;
  }
  &__label {
    position: absolute;
    top: 0px;
    left: 11px;
    z-index: 1;
    padding: 0 3px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #a0a0a0;
    // background: #fff;
    white-space: nowrap;

    width: auto;
    max-width: calc(100% - 15px);
    overflow: hidden;
    text-overflow: ellipsis;
    &:not(:empty) {
      // height: 8px;
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 6px;
      border-bottom: 3px solid #fff;
      z-index: -1;
    }
  }
  &.is-invalid {
    .labeled__cont {
      border-color: #ca1226;
      box-shadow: 0 0 6px rgba(#ca1226, .2);
    }
    .labeled__label {
      color: #ca1226;
    }
  }
}