@media screen and (max-width: 1024px) {
  .express{
    .textbox{
      width: 90vw !important;
      margin-left: 5vw !important;
      left: 0 !important;

      &__span{
        width: 80vw;
        text-align: left;
        margin-top: 30vw;
      }
    }
  }

  .exp-ul{

    li{
      
    }
  }
}