.ml-10{
  margin-left: 10%;
}
.page{
  margin-top:15px;
  padding-bottom: 40px;
}

.content{
  width:100%;
  margin-top:1vw;
  height: fit-content;

  &__banner{
    width:90%;
    display:flex;
    justify-content:center;
    height:4vw;
  
    span{
      font-size:1.8vw;
      font-family:OpenSans-Light;
      line-height:2.5vw;
    
      &.bold{
        font-family:OpenSans-SemiBold;
      }
    }

    &__image{
      margin-right:0.8vw;
      height:3.8vw;
    }
  }

  &-promo{
    color:#fff;
    position: absolute;
    font-size: 20px;
    font-family: Roboto;
    // font-weight: 500;
    right: 0;
    bottom: 0;
    margin-bottom: 0;

    span{
      transform: rotate(-45deg);
      position: absolute;
      bottom: 0;
      right: 60px;
    }

    &::after{
      display: block;
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-bottom: 125px solid $red;
      border-left: 125px solid transparent;
      z-index: -1;
    }
  }

  &__list{
    margin-top:2vw;
    display:flex;
    justify-content:center;
    align-items:flex-start;
    height: 31vw;

    &.services{
      height: auto;

      .item{
        height: 17vw;
        width: 20vw;

        &:hover{
          height: 17vw;

          .text-box{
            height: 17vw;
            background-color: #f3f3f3;
            box-shadow: 0 5px 13px 3px rgba(121, 132, 172, 0.4);
            color: $text-color;
            border-radius: 5px;
            transition: background-color ease 0.8s;

            i{
              color: $red;
              background-color: transparent;
            }
          }

        }

        .text-box{
          width: 20vw;
          padding-top: 1vw;
          height: 17vw;
          background-color: transparent;
          color: $text-color;
          box-shadow: 0 5px 13px 3px rgba(121, 132, 172, 0.2);

          .services-title{
            font-family: OpenSans-Bold;
            font-size: 1.2vw;
          }

          .readmore{
            font-family: OpenSans-Light;
            color: $red;
            font-size: 0.8vw;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            position: absolute;
            bottom: 1.5vw;

            *{
              margin: 0;
              width: fit-content;
            }

            i{
              font-size: inherit;
              background-color: transparent;
            }
          }

          span{
            margin: 0;
            font-size: 1vw;
          }

          i{
            margin-top: 1vw;
            font-size: 5vw;
            margin-bottom: 2vw;
            width: fit-content;
            margin-left: auto;
            margin-right: auto;
            background-color: rgba(244, 244, 244, 0.64);
            border-radius: 100%;
          }

        }
      }
    }


    .item{
      display:inline-block;
      cursor:pointer;
      text-decoration:none;
      color:#0d0802;
      // max-height: 31vw;
      height: auto;
      width: 25vw;
      margin-right:48px;
      text-align:center;


      &.services{
        height: 27vw;
        width: 27vw;

        &:hover{
          height:27vw;

          img{
            bottom: 122px !important;
          }
        }

        img{
          width: 27vw;
          position:relative;
          bottom:98px !important;
          right:0;
        }
      }

      &:last-of-type{
        margin-right: 0;
      }
    
      img{
        width:25vw;
        position:relative;
        bottom:0;
        right:0;
      }

      span{
        display: block;
        font-family: Roboto;
        font-weight: 400;
        font-size: 1.25rem;
        width: 100%;

        &.bold{
          font-weight:700;
        }
      }

      .text-box{
        display:flex;
        flex-direction: column;
        padding-top:0;
        height:126px;
        z-index:1;
        // color: $text-color;
        // background-color:#dedede;
        background-color:$red;
        color: #ffffff;

        .afterline{
          display: none;
        }

        span{
          margin-top: 20px;

          &:last-of-type{
            font-weight: 100;
            margin-top: 15px;

          }
        }
      }

      &:hover{
        height:auto;

        .content-promo{
          bottom: 24px;
        }

        img{
          // margin-top:-26px;
          bottom:24px;
          // border-bottom: 4px solid $red;
        }

        .text-box{
          background-color:$red;
          color: #ffffff;
          height: 150px;

          .afterline{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 20px;

            p{

              &:last-of-type{
                font-size: 9px;
              }

              i{
                margin-right: 4px;

                &:last-of-type{
                  margin-right: 0;
                }
              }
            }

            &-line{
              width: 82%;
              margin-right: 2%;

              &::after{
                display: block;
                content: '';
                width: 100%;
                height: auto;
                border-bottom: 1px solid #fff;
              }
            }
          }
        }
      }
    }
  }
}

.services__title{
  margin-bottom: 35px;
  font-family: Roboto;
  font-weight: 500;
  font-size: 28px;
  margin-left: 96px;
}

@media screen and (max-width: 1024px) {
  .readmore{
    span{
      color: $red !important;
    }
  }

  .afterline, .content-promo{
    display: none;
  }
  .item.main{
    margin-left: 0 !important;
  }
  .services{
    &__title{
      display: none;
      margin: 60px 20px 0;
    }

    &-title{
      color: $text-color;
    }
  }

  // .container-full{
  //   margin-top: 80px;
  // }

  .content{
    margin-top: 95px;

    &__banner{
      width: 100vw;
      height: 12vw;
      align-items: center;
      margin-bottom: 30px;

      &__image{
        width: auto;
        height: 5vw;
        
      }

      span{
        font-size: 18px;
        // font-family: Roboto;
        line-height: 2.25rem;
      }
    }

    &__list{
      flex-wrap: wrap;
      padding: 0 2vw;
      margin: 0 auto;
      width: 96vw;
      height: fit-content;

      &.services{
        width: 75vw;
        margin: 0 auto;
        padding: 0;

        .item{
          width: 90vw;
          height: 134px;
          max-width: 400px;
          margin-bottom:  10px !important;
          padding: 6vw 2vw;
          flex-direction: row;
          background-color: #fff;
          box-shadow: 0 5px 13px 3px rgba(121,132,172,.2);
          border-radius: 5px;
          align-items: center;
          justify-content: center;
          

          &:hover{
            height: 134px;

            .text-box{
              height: auto;
              box-shadow: none;
              background-color: transparent;
            }
          }

          .text-box{
            box-shadow: none;
            margin: 0 0 0 8%;
            padding: 0;
            width: 50%;
            height: auto;
            justify-content: space-evenly;
            
            span{
              font-size: 3vw;

              &:nth-of-type(2){
                color: #000;
                display: none;
              }
            }
            
            .services-title{
              font-size: 16px;
              color: $text-color;
            }
            
            .readmore{
              margin: 10% 0 0 0;
              width: fit-content;
              position: unset;
              font-size: 11px;
              color: $red;

              span{
                font-size: 12px;
                font-weight: 600;
              }
            }
          }

          i{
            margin-left: 6%;
            font-size: 81px;
            background-color: rgba(244, 244, 244, 0.64);
            border-radius: 100%;
          }
        }
      }


      .item{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        width: 100%;
        background-color: $red;
        height: fit-content;
        padding: 15px 20px;
        margin: 0 auto 25px auto !important;
        justify-content: flex-end;
  
        .text-box{
          display: flex;
          margin-top: 0px;
          flex-wrap: wrap;
          align-items: flex-start;
          padding-top: 0;
          margin-left: 10px;
          text-align: left;
          height: fit-content;
          z-index: -1;
          background-color: $red;

          span{
            line-height: 1;
            font-weight: 500;
            color: #fff;
            margin-top: 0;

            &:nth-of-type(2){
              margin-top: 1rem;
              color: #fff;
              font-weight: 100;
              font-size: 16px;
            }
          }
        }

        &.services{
          width: 100%;
          height: fit-content;

          &:hover{
            height: fit-content;
          }
        
          img{
            width: 40%;
            bottom: 0 !important;
          }

        }
        

        img{
          width: 40%;
          margin-right: 10%;
          border-radius: 50%;
          
        }

        span{
          font-size: 20px;
        }

        &:hover{
          height:fit-content;
          
  
          img{
            margin-top:0;
            bottom:0;
            border-bottom: none;
          }
  
          .text-box{
            background-color:initial;
            height: fit-content;

            .afterline{
              display: none;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .content{

    &__list{

      .item{

        span{
          font-size: 1.2rem
        }
      }
    }
  }

  .economy{

    &.second{
      height: 15vw !important;
    }

    ul{
      margin-bottom: 0;

      &:first-of-type{
        padding-top: 10px;
      }

      &:last-of-type{
        margin-top: -50px;
      }
    }
  }

  .del{
    .leftside-text{
      width: 30vw !important;
      font-size: 14px !important;
      line-height: 1.5 !important;
    }
  }
  .relations__item.express{
    margin-bottom: 1.2vw;
    width: 47%;
  }
}