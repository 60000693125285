.navi {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navi-link {
  display: block;
  padding: $nav-link-padding-y $nav-link-padding-x;

  @include hover-focus {
    text-decoration: none;
  }

  // Disabled state lightens text
  &.disabled {
    color: $nav-link-disabled-color;
    pointer-events: none;
    cursor: default;
  }
}

//
// Tabs
//

.navi-tabs {
  border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;

  .navi-item {
    margin-bottom: -$nav-tabs-border-width;
  }

  .navi-link {
    border: $nav-tabs-border-width solid transparent;
    @include border-top-radius($nav-tabs-border-radius);

    @include hover-focus {
      border-color: $nav-tabs-link-hover-border-color;
    }

    &.disabled {
      color: $nav-link-disabled-color;
      background-color: transparent;
      border-color: transparent;
    }
  }

  .navi-link.active,
  .navi-item.show .navi-link {
    color: $nav-tabs-link-active-color;
    background-color: $nav-tabs-link-active-bg;
    border-color: $nav-tabs-link-active-border-color;
  }

  .dropdown-menu {
    // Make dropdown border overlap tab border
    margin-top: -$nav-tabs-border-width;
    // Remove the top rounded corners here since there is a hard edge above the menu
    @include border-top-radius(0);
  }
}

.navi-pills {
  .navi-link {
    @include border-radius($nav-pills-border-radius);
  }

  .navi-link.active,
  .show > .navi-link {
    color: $nav-pills-link-active-color !important;
    background-color: $nav-pills-link-active-bg !important;
  }
}