.mobile{
  display: none !important;
}

.nav{
  position: fixed;
  width: 100%;
  top: 7vw;
  left: 0;
  height:120px;
  z-index: 100;
  box-shadow:0 3px 8px 0 rgba(164,177,192,.15);
  background-color:#fff;
  display:none;
  
  &__overlay{
  display: none;    
  }

  &__menu{
    display:flex;
    justify-content:center;
    border-top:1px solid #d4d4db;
    align-items:center;
    height:100%;
  
    &__item{
      display:inline-block;
      width:200px;
      text-align:center;
      height:16px;
      font-family:OpenSans-Bold;
      font-size:16px;

      a{
        text-decoration:none;
        color:#6f6f6f;
      }
    }
  }
  &.show{
    display:block;
  }
  &.news{
    top: calc(7vw + 48px);
  }
}

.nav-mobile {
  &-title {
    font-weight: 500;
    font-size: 14px;
    color: #6f6f6f;
    padding: 7px 25px;
    background: #f4f4f4;
    &--sub {
      font-size: 12px;
      color: #909498;
      font-weight: normal;
    }
  }

  &-item {
    text-decoration: none;
    padding: 7px 25px;
    border-bottom: solid 1px #e5e6e8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    &:hover {
      text-decoration: none;
    }
    &:focus {
      opacity: 0.7;
    }
    &__text {
      font-size: 14px;
      font-weight: 500;
      color: #393939;
    }
    &__subtext {
      color: #909498;
      font-size: 12px;
    }
    &__icon {
      position: absolute;
      right: 0;
      height: 100%;
      display: flex;
      color: #b2b4b3;
    }
  }
}


@media screen and (max-width: 1024px) {
  .mobile{
    display: block !important;
  }
  .nav{
    height: 100%;
    width: 100vw;
    position: fixed;
    top: 72px;
    margin-top: 0px;
    background-color: transparent;

    &.show{
      display: flex;
    }

    &__overlay{
      display: inline-block;
      background-color: #000;
      height: 100%;
      width: 35vw;
      opacity: 0.51;
    }

    &__menu{
      display: inline-block;
      padding: 25px 0;
      width: 65vw;
      background-color: #fff;
      height: auto;
      overflow: auto;
      margin-bottom: 60px;
      min-width: 241px;

      &__item{
        padding-right: 20px;
        display: block;
        width: 100%;
        text-align: right;
        font-size: 15px;
        height: 40px;
        font-family: Roboto;
        font-weight: 500;
        color: #393939;

        &.mobile{
          display: block;
        }
      }

      div{
        display: flex;
        justify-content: flex-end;

        .nav__menu__item{
          display: inline-block;
          width: auto;
        }
      }
    }
    &.news{
      top: 120px;
    }
  }
}

.nav-sidebar {
  background: rgba(244, 244, 244, 0.43);
  padding: 20px 25px;
  &-item {
    a {
      text-decoration: none;
      padding: 9px 17px;
      display: block;
      font-weight: 500;
      font-size: 15px;
      color: #6f6f6f;
      border-left: 2px solid #e4e7ed;
      &:hover {
        text-decoration: none;
        color: #6f6f6f;
      }
    }
    &--active {
      a {
        color: $red;
        border-color: $red;
        &:hover {
          color: $red;
        }
      }
    }
  }
}

