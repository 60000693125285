.c-tip {
  display: inline-block;
  position: relative;
  font-size: 0px;
  word-break: normal;

  &__text {
    display: none;
    position: absolute;
    z-index: 9999;
    top: 50%;
    left: 100%;
    transform: translate(8px, -50%);
    background-color: #000;
    border-radius: 4px;
    color: #fff;
    padding: 8px 12px;
    font-size: 12px;
    line-height: 16px;
    // min-width: 100px;
    min-width: 200px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      width: 6px;
      height: 6px;
      transform: translate(-3px, -50%) rotate(45deg);
      background: #000;
    }
    &.top {
      top: unset;
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, -8px);
      &:before {
        left: 50%;
        top: 100%;
        transform: translate(-50%, -3px) rotate(45deg);
      }
    }
    &.left {
      top: 50%;
      left: unset;
      right: 100%;
      transform: translate(-8px, -50%);
      &:before {
        left: unset;
        right: 0;
        top: 50%;
        transform: translate(3px, -50%) rotate(45deg);
      }
    }
  }
  .cont:hover + .c-tip__text {
    display: block;
  }
}