.screenbld{
  &.first{
    background-image:url(/static/img/build/build-1.jpg);
    background-size:cover;
    width: 92vw;
    margin-left:4vw;
    height:43vh;

    &.about{
      background-image: url(/static/img/about/about-1.jpg);
      margin-top: 2vw;
      width: 90vw;
      margin-left: 5vw;
    }
  }

  &.second{
    margin-top: 0;
    position: absolute;
    background-image: url(/static/img/build/build-2.jpg);
    background-size: cover;
    background-clip: border-box;
    transform: none;
    top: 0;
    right: 3.6vw;
    height: auto;
    width: 44.9vw;
    bottom: 20%;
    background-position: left;
  }
}
.textbox__mintext{
  width: 40vw;
  font-size:1.1vw;
  line-height:1.25;
  margin-top:1.25vw !important;
  font-family:SourceSansPro-Light;
}
.bld{
  .textbox{
    top:1vw;
    width:60vw;
    left:calc(50% - 30vw);
    text-align:left;

    &.left{
      left: 5%;
    }

    &__mintext{
      width: 40vw;
      font-size:1.1vw;
      line-height:1.25;
      margin-top:1.25vw !important;
      font-family:SourceSansPro-Light;
    }
    
    &__image{
      height:4.25vw;
      margin-top:0.9vw;
      margin-right:0.75vw;
      display:inline;
    }

    &__border{
      align-items:flex-start;
      margin-top:0.5vw;
      margin-left: 0;
      padding-left:1vw;
      text-align:left;
      font-size: 48px;

      p{
        margin: 0;
      }

      &.therm{

        .bold, p{
          display: inline-block;
        }
      }
    }
  }

  .relations{
    margin-top: 1vw;
    margin-bottom: 2vw;
  }
  .economy {
    margin-top: 2vw;
    
    &.second{
      padding-top: 2vw;
      margin-top: 1vw;
    }

    &.left{
      margin-left: 5%;
      font-size: 1vw;
      width: 11vw;
      height: 3vw;
      cursor: pointer;
      margin-top: 2.5vw;
    }

    li{
      width:15vw
    }
  }
}
.last{
  background-image:url(/static/img/last.svg);
  background-clip:border-box;
  align-items: center;
  background-size:cover;
  background-position-y:center;
  height:76%;
  width:85%;
  margin-top:2.5%;
  margin-left:7.5%;

  .textbox{
    &__border{
      width:40vw;
      border-color:#fff;
      color:#fff;
      text-align:left;
      padding-left:1.5vw;
    }

    &__mintext{
      font-size:1vw;
      margin-top:2vw;
    }
  }
  .number{
    color:#fff;
    font-size:2vw;
    font-family:OpenSans-SemiBold;
    position:relative;
    top:32.5%;
    left:41%;
  }
}

.slide-response{
  cursor:pointer;
  position:absolute;
  font-size:0.9vw;
  font-family:OpenSans-Regular;
  background-color:#fafafa;
  color:#ca1226;
  border:none;
  -webkit-appearance:none;
  height:2.25vw;
  width:21vw;
  bottom:22%;
  left:40.5%;
}

@media screen and (max-width: 1024px) {
  .build-up-textbox{
    width: 93vw !important;
    margin-left: 0 !important;
  }
  .desktop{
    display: none !important;
  }
  .screenbld{
    &.first{
      background-image:url(/static/img/build/build-1.jpg);
      background-size:cover;
      width: 100vw;
      margin: 20px auto;
      background-position-x: -270px;
      height: 55vw;
  
      &.about{
        background-image: url(/static/img/about/about-1.jpg);
        margin: 0px 0 50px 0px;
        width: 100vw;
      }
    }
  
    &.second{
      margin-top: 56px;
      position: unset;
      background-image: url(/static/img/build/build-2.jpg);
      background-size: cover;
      height: 50vw;
      width: 100vw;
      margin-bottom: 50px;
      background-clip: border-box;
      transform: none;
    }
  }
  .bld{
    .textbox{
      top:1vw;
      width: 90vw;
      left: 0;
      margin: 0;
      text-align:left;
  
      &__mintext{
        font-size:16px;
        line-height:1.15;
        margin-top:10px;
        font-family:SourceSansPro-Light;
        color: #0d0802;
      }
      
      &__image{
        height:30px;
        margin: auto 0;
        display:inline-block;
      }
  
      &__border{
        align-items:flex-start;
        width: 80% !important;
        margin:0 auto !important;
        padding-left:7px;
        text-align:left;
        font-size: 18px;

        &.first{
          width: 75% !important;
        }
  
        p{
          margin: 0;
        }
  
        &.therm{
  
          .bold, p{
            display: inline;
          }
        }
      }
    }
  
    .relations{
      display: none;
      margin-top:1.5vw;

      &-block{
        margin-top: 0px;
        padding-left: 6%;
        font-size: 16px;
      }
    }

    .economy {
      width: 90%!important;
      margin-left: 5%!important;
      margin-top: 35px;
      height: 60px !important;
      font-size: 20px !important;
      
      &.second{
        transform: none;
        margin-top: 15vw;
        width: 100vw !important;
        height: 90vw !important;
        margin-left: 0 !important;

        ul{
          display: inline-block;
          width: 70%;
          margin:auto 0;
    
          &:first-of-type{
            width: 30%;
            padding-top: 20px;
            padding-left: 30px;

          }
        }
      }
  
      li{
        display: block;
        font-size: 16px;
        width: 90% !important;
        text-align: left;
        height: 50px;
        margin-bottom: 15px;

        .number{
          font-size: 30px;
          padding: 5px 16px;
        }
      }
    }
  }
  .last{
    background-image:url(/static/img/last.svg);
    background-clip:border-box;
    align-items: center;
    background-size:cover;
    background-position-y:center;
    height:76%;
    width:85%;
    margin-top:2.5%;
    margin-left:7.5%;
  
    .textbox{
      &__border{
        width:40vw;
        border-color:#fff;
        color:#fff;
        text-align:left;
        padding-left:1.5vw;
      }
  
      &__mintext{
        font-size:14px;
        margin-top:10px;
      }
    }
    .number{
      color:#fff;
      font-size:2vw;
      font-family:OpenSans-SemiBold;
      position:relative;
      top:32.5%;
      left:41%;
    }
  }
  
  .slide-response{
    cursor:pointer;
    position:absolute;
    font-size:0.9vw;
    font-family:OpenSans-Regular;
    background-color:#fafafa;
    color:#ca1226;
    border:none;
    -webkit-appearance:none;
    height:2.25vw;
    width:21vw;
    bottom:22%;
    left:40.5%;
  }
}