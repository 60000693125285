sub, sup {
	line-height: 0;
}
.stocks.track{
  padding-bottom:0;
}
.tracking{
  width: 100%;
  margin-bottom: 160px;

  &__title{
    margin-bottom:35px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 28px;
  }

  &__form{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 !important;
  }

  &__input{
    width: 87%!important;
    height: auto;
    border-radius: 3px;
    border: 1px solid #d7d7d7;
    color: #000!important;
    font-family: Roboto;
    font-size: 21px;
    font-weight: 400;
    padding-left: 15px;
    -webkit-appearance: none;

    &:focus{
      outline: none;
    }
  }

  &__submit{
    cursor: pointer;
    background-color: #ca1226;
    -webkit-appearance: none;
    border: none;
    border-radius: 3px;
    color: #fff;
    font-family: Roboto;
    font-size: 1.2rem;
    font-weight: 400;
    padding: 7px 1.5vw;

    &:focus{
      outline: none;
    }
  }
  
  &__container{
    background-color: rgba(244, 244, 244, 0.54);
  }

  &__info{
    margin-top: 30px;
    padding: 30px 40px 15px;
    display: flex;
    justify-content: space-between;
  }

  &__list{
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    width: 50%;

    &.fact{
      width: 320px;

      .tracking__item:last-of-type{
        justify-content:flex-end;
      }
    }
  }

  &__item{
    display: flex;
    font-family: Roboto;
    width: 100%;
    height: auto;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 5px;
  }

  &__greytext{
    color: #909498;
  }

  &__data{
    width: 75%;
    color: $text-color;
  }

  &__link{
    cursor: pointer;
    color: $red;
    font-size: 20px;

    &:hover{
      color: #ca1226;
    }
  }

  &__fact{
    line-height: 20px;
    align-items: center;
    display: flex;

    &__icon{
      height: 24px;
      margin-right: 15px;
      opacity: 0.7;

      &.first{
        height: 30px;
      }
    }
  }

  &__display{
    display: flex;
    justify-content: space-between;
    padding: 30px 40px 25px;
    background-color: #f0f0f0;
  }

  &__points{
    max-width: 20%;
    position: relative;

    &:last-of-type{
      .tracking{
        &__point-info{
          width: 100%;
          text-align: right;
        }
      }
    }

    div{

      .tracking__point-info:first-of-type{
        margin-bottom: 14px;
      }
    }
  }

  &__icon{
    height: 55px;
    margin-top:2px;
  }

  &__point-info{
    width: fit-content;
    font-family: Roboto;
    height: auto;
    font-size: 20px;
    font-weight: 400;

    &:first-of-type{
      margin-bottom: 40px;
      height: auto;
    }
  }

  &__city{
    color: $text-color;
  }

  &__status{
    color: #6f6f6f;
  }

  &__date{
    font-size: 18px;
    color: #909498;
    margin-top: 11px;
    font-family: Roboto;
    font-weight: 400;
  }

  &__dummy{
    display: flex;
    height: 480px;
    justify-content: center;
    padding-top: 200px;

    &-box{
      width: fit-content;
      text-align: center;
    }

    &-text{
      color: #aeb4bf;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.14;
    }

    &-title{
      font-size: 1.5rem;
      margin-bottom: 10px;
    }

    &-icon{
      font-size: 8rem;
      color: #aeb4bf;
    }
  }
}
@media screen and (max-width: 1024px) {
  .tracking{
    width: 100%;
    margin-bottom: 0px;
    padding-bottom: 0px;

    &__link{
      font-size: 14px;
    }

    &__item{
      display: flex;
      font-family: Roboto;
      width: 100%;
      height: auto;
      justify-content: space-between;
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 7px;
    }

    &__input{
      width: 100%!important;
      min-height: 33px;
      height: 33px;
      padding: 0 6px 0 12px;
      border-radius: 3px;
      font-family: Roboto;
      font-size: 14px;

      &::placeholder{

      }

    }

    &__title{
      font-size: 18px;
      font-family: Roboto;
      font-weight: 500;
      margin-bottom: 30px;
    }

    &__submit{
      width: 100%;
      margin-top: 20px;
      min-height: 40px;
      border-radius: 3px;
      background-color: #ca1226;
      color: #fff;
      padding: 10px 35px;
      word-break: break-word;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      border: 1px solid #ca1226;
      transition: color .3s,background-color .3s;
    }

    &__info{
      margin: 40px 0 20px !important;
      padding: 0;
      height: 310px;
    }

    &__data{
      max-width: 65%;
      line-height: 17px;
      text-align: right;
    }

    &__list{
      padding-left: 0;
      padding-right: 0;
      width: 100vw;
      margin-bottom: 13px;

    }

    &__container{
      background: transparent;
    }

    &__fact{
      margin-bottom: 0px;
      
      &:last-of-type{
        margin-left: 19px;
      
      }

      &__icon{
        height: 20px;
        margin-right: 5px;

        &.first{
          height: 25px;
        }
      }
    }

    &__list.fact{
      width: 100%;
      padding: 0;
      margin-top: 0px;
      margin-bottom: 80px;

      .tracking__item{
        padding: 13px 15px;
        background-color: #eaeaed;
        position: relative;
        left: 2px;
        justify-content: space-between;

        &:last-of-type{
          background-color: transparent;
          position: relative;
          justify-content: flex-start;
          bottom: -17px;
          margin: 0 auto;
          width: 95%;      
          border-radius: 4px;
          box-shadow: 0 0 9px 1px rgba(121,132,172,.18);
        }
      }
    }

    &__display{
      flex-wrap: wrap;
      width: 100%;
      background-color: transparent;
      justify-content: flex-start;
      padding: 0;
    }

    &__points{
      align-items: flex-start;
      display: block;
      width: 100%;
      max-width: none;
      right: 0 !important;
      margin-bottom: 20px;

      &:last-of-type{
        .tracking{
          &__point-info{
            width: auto;
            text-align: left;
          }
        }
      }

      div{
        display: inline-block;

        .tracking__point-info{
          margin: 0px;
        }
      }
    }


    &__point-info{
      &:first-of-type{
        display: inline-block;
      }
    }

    &__icon{
      width:25px;
      height: 70px;
    }
  
    &__dummy{
      height: 48vw;
      padding-top: 10px;
    }
  }
}

@media screen and (min-width:1024px) and (max-width: 1366px) {
  .tracking{

    &__input{
      width: 83% !important;
    }
  }
}