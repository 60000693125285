@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(~src/fonts/Roboto-Regular.ttf);
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url(~src/fonts/Roboto-Medium.ttf);
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url(~src/fonts/Roboto-Light.ttf);
}
@font-face{
  font-family: "OpenSans-Bold";
  font-style: normal;
  font-weight: normal;
  src: url(~src/fonts/OpenSans-Bold.ttf);
}
@font-face{
  font-family: "OpenSans-SemiBold";
  font-style: normal;
  font-weight: normal;
  src: url(~src/fonts/OpenSans-SemiBold.ttf);
}
@font-face{
  font-family: "OpenSans-Light";
  font-style: normal;
  font-weight: normal;
  src: url(~src/fonts/OpenSans-Light.ttf);
}
@font-face{
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: normal;
  src: url(~src/fonts/OpenSans-Regular.ttf);
}
@font-face{
  font-family: "SourceSansPro-Light";
  font-style: normal;
  font-weight: normal;
  src: url(~src/fonts/SourceSansPro-Light_0.otf);
}
@font-face{
  font-family: "SourceSansPro-Semibold";
  font-style: normal;
  font-weight: normal;
  src: url(~src/fonts/SourceSansPro-Semibold.otf);
}
@font-face {
  font-family: 'AGHelveticaBoldItalic';
  src:  url(~src/fonts/AGHelveticaBoldItalic/AGHelveticaBoldItalic.eot) format('embedded-opentype'), url(~src/fonts/AGHelveticaBoldItalic/AGHelveticaBoldItalic.woff) format('woff'), url(~src/fonts/AGHelveticaBoldItalic/AGHelveticaBoldItalic.ttf)  format('truetype'), url(~src/fonts/AGHelveticaBoldItalic/AGHelveticaBoldItalic.svg) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
	font-family: 'Avanti-Bold';
	src: url(~src/fonts/avanti-bold/avanti-bold_[allfont.ru].eot), url(~src/fonts/avanti-bold/avanti-bold_[allfont.ru].woff) format('woff'), url(~src/fonts/avanti-bold/avanti-bold_[allfont.ru].ttf) format('truetype'), url(~src/fonts/avanti-bold/avanti-bold_[allfont.ru].svg) format('svg');
	font-weight: normal;
	font-style: normal;
}