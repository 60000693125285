.vacancy {
  &-card {
    padding-bottom: 20px;
    border-bottom: 1px solid #e5e6e8;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border: none;
    }
    &-header {
      &__actions {
        li {
          display: inline-block;
          margin-right: 30px;
          color: #6f6f6f;
          &:last-child {
            margin-right: 0;
          }
          i {
            color: $red;
            font-size: 17px;
            margin-right: 5px;
            position: relative;
            top: 3px;
          }
        }
      }
    }
    &-body {
      &__text {
        line-height: 16px;
      }
      &__list {
        li {
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &-shadow {
      border-radius: 4px;
      box-shadow: 0 0 9px 1px rgba(121, 132, 172, 0.18);
      border-bottom: none;
      overflow: hidden;
      &:last-child {
        padding-bottom: 20px;
      }
      .vacancy {
        &-card {
          &-header {
            &__name {
              font-size: 14px;
              padding: 13px 20px;
              &--bg {
                background: $red;
                color: #ffffff;
                text-align: center;
                font-weight: normal !important;
                font-size: 14px !important;
              }
            }
            &__actions {
              margin: 0 20px;
            }
          }
          &-body {
            padding: 15px 20px;
          }
          &-footer {
            padding: 0 20px;
            text-align: right;
            &__more {
              font-size: 12px;
              font-weight: 500;
              i {
                font-size: 8px;
                margin-left: 5px;
              }
            }
          }
        }
      }
    }

    &--mobile {
      .vacancy {
        &-card {
          &-header {
            &__actions {
              padding-bottom: 20px;
              border-bottom: 1px solid #e5e6e8;
              li {
                display: block;
                margin-bottom: 8px;
                color: #393939;
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
          &-body {
            .parse-content-simple {
              color: #909498;
            }
          }
          &-footer {
            text-align: right;
            &__more {
              font-size: 12px;
              font-weight: 500;
              i {
                font-size: 8px;
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}