.c-input {
  width: 100%;
  border: none;
  min-height: 33px;
  padding: 0 6px 0 12px;
  border-radius: 3px;

  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #393939;

  outline: none;
  // &:disabled {
  //   background-color: #f8f8f8;
  //   color: #aaa;
  //   // cursor: not-allowed;
  //   &::placeholder {
  //     color: #aaa;
  //   }
  // }
  &__cont {
    fieldset:disabled &, &.disabled {
      .labeled__cont {
        background-color: #f8f8f8;
        cursor: not-allowed;
      }
      .c-input__suffix {
        color: #909498;
      }
      input {
        cursor: not-allowed;
        color: #909498 !important;
        background-color: transparent;
        &::placeholder {
          color: #909498 !important;
        }
      }
    }
  }
  &__suffix {
    position: relative;
    top: 0px;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: 14px;
    letter-spacing: normal;
    color: #909498;
    sup {
      vertical-align: super;
      font-size: smaller;
    }
  }

  &.large {
    min-height: 38px;
  }
}

textarea.c-input {
  margin-top: 7px;
  min-height: 70px;
}